import * as Yup from "yup";

export const descriptionValidationSchemas = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(100, "Title cannot exceed 100 characters"),
  property_category: Yup.string().required("Property category is required"),
  property_type_id: Yup.string().required("Property type is required"),
  country: Yup.string().required("Country is required"),
  street: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipcode: Yup.string().required("Zipcode is required"),
  guests: Yup.number()
    .required("Number of guests is required")
    .min(1, "Guests must be at least 1")
    .max(14, "Guests cannot exceed 14"),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters long"),
  cancellation_policy_id: Yup.string().required(
    "Cancellation policy is required"
  ),
  bedroom_description: Yup.string()
    .required("Bedroom description is required")
    .min(10, "Bedroom description must be at least 10 characters long"),
  university_nearby: Yup.string()
    .required("University nearby field is required")
    .min(10, "Please provide a meaningful description"),
  about_neighbourhoods: Yup.string()
    .required("About neighbourhoods field is required")
    .min(10, "Please provide a meaningful description"),
});

export const PricevalidationSchemas = Yup.object().shape({
  price_per_month: Yup.number().when("isSharedProperty", (allowed, schema) => {
    if (!allowed[0]) {
      return schema
        .required("Price per month is required")
        .min(0, "Price per month must be greater than zero");
    }
    return schema.notRequired();
  }),
  taxes_in_percentage: Yup.number()
    .required("Taxes are required")
    .min(0, "Taxes cannot be negative")
    .max(100, "Taxes cannot exceed 100%"),
  cleaning_fee: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Treat empty strings as null
    .nullable()
    .notRequired()
    .min(0, "Cleaning fee cannot be negative"),
  city_fee: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Treat empty strings as null
    .nullable()
    .notRequired()
    .min(0, "City fee cannot be negative"),
  security_deposit: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Treat empty strings as null
    .nullable()
    .notRequired()
    .min(0, "Security deposit fee cannot be negative"),
  is_city_fee_percentage: Yup.boolean(),
  minimum_months_of_booking: Yup.number()
    .required("Minimum months of booking is required")
    .min(1, "Must be at least 1 month")
    .max(12, "Must not exceed 12 months"),
  allow_extra_guest: Yup.boolean(),
  per_extra_guest_amount: Yup.number().when(
    "allow_extra_guest",
    (allowExtraGuest, schema) => {
      if (allowExtraGuest[0]) {
        return schema
          .required("Price of extra guests is required")
          .min(0, "Price cannot be negative");
      }
      return schema.notRequired();
    }
  ),
});

export const ListingDetailsvalidationSchema = Yup.object({
  property_size: Yup.number()
    .typeError("Property size must be a number")
    .positive("Property size must be positive")
    .required("Property size is required"),
  total_rooms: Yup.number()
    .integer("Rooms must be an integer")
    .positive("Rooms must be a positive number")
    .required("Number of rooms is required")
    .min(1, "Minimum one room is required")
    .max(20, "Room can not be greater than 20"),
  total_bedrooms: Yup.number()
    .integer("Bedrooms must be an integer")
    .required("Number of bedrooms is required")
    .min(1, "Minimum one bedroom is required")
    .max(20, "Bedroom can not be greater than 20"),
  total_bathrooms: Yup.number()
    .integer("Bathrooms must be an integer")
    .positive("Bathrooms must be a positive number")
    .required("Number of bathrooms is required")
    .min(1, "Minimum one bathroom is required")
    .max(20, "Bathroom can not be greater than 20"),
  additional_rules_attributes: Yup.array().of(
    Yup.string().required("Additional rule cannot be empty")
  ),
});

// validation schema from signup
export const SignUpSchemaForStudent = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("First name is required"),
  last_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("Last name is required"),
  // username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain"
    )
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password is too short")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least one uppercase letter, one number, and one special character"
    )
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
  date_of_birth: Yup.date()
    .max(new Date(Date.now() - 86400000), "Date of birth invalid")
    .required("Date of birth is required"),
});

// validation schema from signup
export const SignUpSchemaForHost = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("First name is required"),
  last_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("Last name is required"),
  // username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain"
    )
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^\+?[0-9]{1,4}[^\s]+$/, "Phone number must not be empty.")
    //.matches(/^[0-9]{10}$/, "Phone number must be of 10 digits")
    .required("Phone number is required"),

  password: Yup.string()
    .min(6, "Password is too short")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least one uppercase letter, one number, and one special character"
    )
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
  date_of_birth: Yup.date()
    .max(new Date(Date.now() - 86400000), "Date of birth invalid")
    .required("Date of birth is required"),
});

// Validation schema for updating user information
export const UpdateUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("First name is required"),
  last_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain"
    )
    .required("Email is required"),
  date_of_birth: Yup.date()
    .max(new Date(Date.now() - 86400000), "Date of birth invalid")
    .required("Date of birth is required"),
  phone: Yup.string()
    .matches(/^\+?[0-9]{1,4}[^\s]+$/, "Phone number must not be empty.")
    .required("Phone number is required"),
  about_me: Yup.string()
    .required("About me is required")
    .test("min-words", "Must be at least 50 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount >= 50;
    })
    .test("max-words", "Must not exceed 150 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount <= 150; // Check for at most 150 words
    }),
  live_in: Yup.string().required("Nationality is required"),
  country_id: Yup.string()
    .matches(
      /^(?! )[^\s]*(?<! )$/,
      "Country ID must not start or end with whitespace and may contain spaces between characters"
    )
    .required("Identification number is required"),
  gender: Yup.string().nullable(),
  is_student: Yup.boolean(),
  is_host: Yup.boolean(),
  work_place: Yup.string()
    .nullable()
    .matches(/^(?! )[^\s]+(?: [^\s]+)*(?<! )$/, "No whitespace allowed"),
  speak_language: Yup.string()
    .nullable()
    .matches(
      /^(?! )[a-zA-Z\s]+(?<! )$/,
      "Speak language must contain only letters."
    ),
  payment_email: Yup.string()
    .nullable()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain"
    ),
});

// Validation schema for Login
export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password is too short")
    .required("Password is required"),
});

// validation schema for update password
export const passwordvalidationSchema = Yup.object({
  new_Password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least one uppercase letter, one number, and one special character"
    )
    .required("New password is required"),
  confirm_Password: Yup.string()
    .oneOf([Yup.ref("new_Password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
});

export const locationValidationSchema = Yup.object({
  street: Yup.string()
    .required("Street address is required")
    .min(5, "Street address must be at least 5 characters"),
  city: Yup.string()
    .required("City is required")
    .min(2, "City name must be at least 2 characters"),
  state: Yup.string()
    .required("State is required")
    .min(2, "State name must be at least 2 characters"),
  zipcode: Yup.string()
    .required("Zip code is required")
    .matches(/^[0-9]{5,6}$/, "Postal code must be between 5 and 6 digits"),
  country: Yup.string().required("Country is required"),
});

export const SizeValidationSchema = Yup.object().shape({
  acreage: Yup.number()
    .nullable()
    .required("Property Size is required")
    .min(1, "Property Size must be at least 1"),
  guests: Yup.number()
    .nullable()
    .required("Guests are required")
    .min(1, "Guests must be at least 1"),
  bedroom: Yup.number()
    .nullable()
    .required("Bedroom is required")
    .min(1, "Bedroom must be at least 1"),
  bathroom: Yup.number()
    .nullable()
    .required("Bathroom is required")
    .min(1, "Bathroom must be at least 1"),
});

export const amenitiesvalidationSchema = Yup.object({
  amenities: Yup.array()
    .min(1, "Please select at least one amenity")
    .required("Please select at least one amenity"),
  features: Yup.array()
    .min(1, "Please select at least one feature")
    .required("Please select at least one feature"),
});

//validation schema for personal details
export const personalDetailsSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("First name is required"),
  last_name: Yup.string()
    .matches(
      /^(?!.*\s{2})[A-Za-z]+(?: [A-Za-z]+)*$/,
      "First name must contain only letters."
    )
    .required("Last name is required"),
  gender: Yup.string().nullable(),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain"
    )
    .required("Email is required"),
  date_of_birth: Yup.date()
    .max(new Date(Date.now() - 86400000), "Date of birth invalid")
    .required("Date of birth is required"),
  phone: Yup.string()
    .matches(/^\+?[0-9]{1,4}[^\s]+$/, "Phone number must not be empty.")
    // .matches(
    //   /^\+\d{1,3} \d{1,15}$/,
    //   "Phone # WhatsApp must be in the format +[country code] [number] (e.g., +33 232 3322)"
    // )
    .required("Phone/WhatsApp is required"),
  about_me: Yup.string()
    .required("About me is required")
    .test("min-words", "Must be at least 50 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount >= 50;
    })
    .test("max-words", "Must not exceed 150 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount <= 150; // Check for at most 150 words
    }),
  nationality: Yup.string().required("Nationality is required"),
  speak_language: Yup.string()
    .matches(
      /^[a-zA-Z]+[a-zA-Z\s]*$/,
      " Must not start with whitespace, and only contain letters"
    )
    .nullable(),
  tenant_type: Yup.string()
    .required("Tenant type is required")
    .test(
      "is-valid-tenant-type",
      'Tenant type must be either "student" or "professional"',
      (value) => {
        return ["student", "professional"].includes(value);
      }
    ),
  home_university: Yup.string()
    .matches(
      /^[a-zA-Z]+[a-zA-Z\s]*$/,
      " Must not start with whitespace, and only contain letters"
    )
    .when("tenant_type", {
      is: (tenant_type: string) => tenant_type === "student",
      then: (schema) => schema.required("Home University is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

  abroad_university: Yup.string()
    .matches(
      /^[a-zA-Z]+[a-zA-Z\s]*$/,
      "Must not start with whitespace, and only contain letters"
    )
    .when("tenant_type", {
      is: (tenant_type: string) => tenant_type === "student",
      then: (schema) => schema.required("Abroad University is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

  education_major: Yup.string()
    .matches(/^(?! )[a-zA-Z\s]*(?<! )$/, " must not start with whitespace")
    .when("tenant_type", {
      is: (tenant_type: string) => tenant_type === "student",
      then: (schema) => schema.required("Education Major is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

  company_name: Yup.string()
    .matches(/^(?! )[a-zA-Z\s]*(?<! )$/, " must not start with whitespace")
    .when("tenant_type", {
      is: (tenant_type: string) => tenant_type === "professional",
      then: (schema) => schema.required("Company Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

  live_in: Yup.string().when("tenant_type", {
    is: (tenant_type: string) => tenant_type === "professional",
    then: (schema) => schema.required("Current Location is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

// validation schema for social media links
const addProtocol = (url: string) => {
  if (url && !/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export const socialMediaDetailsSchema = Yup.object().shape({
  facebook_url: Yup.string()
    .transform((value) => addProtocol(value))
    .url("Invalid Facebook URL")
    .nullable(),
  linkedin_url: Yup.string()
    .transform((value) => addProtocol(value))
    .url("Invalid LinkedIn URL")
    .nullable(),
  instagram_url: Yup.string()
    .transform((value) => addProtocol(value))
    .url("Invalid Instagram URL")
    .nullable(),
});

// validation schema for university acceptance
export const universityLOASchema = Yup.object({
  university_loa: Yup.mixed()
    .nullable()
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return true;
      const file = value as File;
      return (
        ["application/pdf", "image/jpg"].includes(file.type) ||
        file.name.endsWith(".jpg")
      );
    })
    .test("fileSize", "File Size is too large", (value) => {
      if (!value) return true;
      const file = value as File;
      return file.size <= 5 * 1024 * 1024;
    }),

  work_loa: Yup.mixed()
    .nullable()
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return true;
      const file = value as File;
      return (
        ["application/pdf", "image/jpg"].includes(file.type) ||
        file.name.endsWith(".jpg")
      );
    })
    .test("fileSize", "File Size is too large", (value) => {
      if (!value) return true;
      const file = value as File;
      return file.size <= 5 * 1024 * 1024;
    }),
});

export const amenitiesValidationSchema = Yup.object().shape({
  amenities: Yup.array()
    .min(1, "Select at least one amenity")
    .required("Please select amenities"),
});

export const createHouseRulesValidationSchema = (houseRules: any[]) => {
  return Yup.object().shape({
    ...houseRules.reduce((acc: any, rule: any) => {
      acc[rule.id] = Yup.string().required("This field is required");
      return acc;
    }, {}),
    // additionalRules: Yup.array()
    //   .of(Yup.string().min(1, "Additional rule cannot be empty"))
    //   .required("At least one additional rule is required")
    //   .min(1, "At least one additional rule is required"),
  });
};

export const descriptionValidationSchema = Yup.object().shape({
  description: Yup.string()
    .min(10, "Description must be at least 10 characters")
    .required("Description is required"),
});

export const PicturevalidationSchema = Yup.object({
  propertySize: Yup.number()
    .required("Property size is required")
    .min(1, "Property size must be greater than 0"),
  coverImage: Yup.mixed().required("Cover image is required"),
  placeImages: Yup.array()
    .min(1, "At least one place image is required")
    .required("Place images are required"),
});

export const PricevalidationSchema = Yup.object().shape({
  pricePerNight: Yup.number()
    .required("Price per month is required")
    .min(1, "Price must be at least $1"),
  // Uncomment these validations if you add these fields back
  // currency: Yup.string().required("Currency is required"),
  // basePriceWeekday: Yup.number()
  //   .required("Base price (Mon-Thu) is required")
  //   .min(0, "Base price cannot be negative"),
  // basePriceWeekend: Yup.number()
  //   .required("Base price (Fri-Sun) is required")
  //   .min(0, "Base price cannot be negative"),
  // longTermDiscount: Yup.number()
  //   .required("Long term discount is required")
  //   .min(0, "Discount cannot be negative"),
});
// export const currencyValidationSchema = Yup.object().shape({
//   currency: Yup.string().required("Currency is required"),
//   basePriceWeekday: Yup.number()
//     .required("Base price is required")
//     .positive("Price must be a positive number")
//     .typeError("Price must be a number"),
//   basePriceWeekend: Yup.number()
//     .required("Base price is required")
//     .positive("Price must be a positive number")
//     .typeError("Price must be a number"),
//   longTermPriceDiscount: Yup.number()
//     .required("Monthly discount is required")
//     .min(0, "Discount must be at least 0%")
//     .max(100, "Discount cannot exceed 100%")
//     .typeError("Discount must be a number"),
// });

export const forgotValidationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
export const creditCardValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name on card is required")
    .matches(/^\S.*$/, "Name cannot start with a space")
    .test(
      "is-not-whitespace",
      "Name on card cannot be empty or contain only spaces",
      (value) => value?.trim().length > 0
    ),

  cc_number: Yup.string()
    .required("Last 4 digits are required")
    .matches(/^\d{4}$/, "Must be exactly 4 digits"),
  month: Yup.string()
    .required("Expiry month is required")
    .matches(/^(0[1-9]|1[0-2])$/, "Must be a valid month")
    .test(
      "is-valid-month",
      "Expiry month cannot be in the past",
      function (value) {
        const { year } = this.parent;
        // Convert the month string to a number before comparison
        const month = Number(value);
        // eslint-disable-next-line eqeqeq
        if (year == currentYear && month < currentMonth) {
          return false;
        }
        return true;
      }
    ),
  year: Yup.string()
    .required("Expiry year is required")
    .matches(/^\d{4}$/, "Must be a valid year")
    .test(
      "is-valid-year",
      "Expiry year cannot be in the past",
      function (value) {
        return Number(value) >= currentYear;
      }
    ),
});

export const paypalValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const roomRuleValidationSchema = Yup.object({
  rooms_attributes: Yup.array().of(
    Yup.object({
      name: Yup.string()
        .required("Name is required")
        .matches(/^[A-Za-z0-9\s]+$/,"Name can only contain letters, numbers, and spaces"),
      price_per_month: Yup.number().when(
        "isSharedProperty",
        (allowed, schema) => {
          if (allowed[0]) {
            return schema
              .required("Price per month is required")
              .min(1, "Price per month must be greater than zero")
              .test(
                "is-positive",
                "Price per month must be a positive value",
                (value) => value > 0
              );
          }
          return schema.notRequired();
        }
      ),
      status: Yup.string().required("Status is required"),
      booking_start: Yup.string().when(
        "isSharedProperty",
        (allowed, schema) => {
          if (allowed[0]) {
            return schema
              .transform((value) =>
                typeof value === "number" ? value.toString() : value
              ) // Normalize to string
              .required("Availability start date is required")
              .test(
                "is-valid-date",
                "Availability start date must be a valid date",
                (value) => {
                  return !isNaN(Date.parse(value || ""));
                }
              );
          }
          return schema.notRequired();
        }
      ),
      booking_end: Yup.string().when("isSharedProperty", (allowed, schema) => {
        if (allowed[0]) {
          return schema
            .transform((value) =>
              typeof value === "number" ? value.toString() : value
            ) // Normalize to string
            .required("Availability end date is required")
            .test(
              "is-valid-date",
              "Availability end date must be a valid date",
              (value) => {
                return !isNaN(Date.parse(value || ""));
              }
            )
            .test(
              "is-after-start",
              "Availability end date must be after the start date",
              function (value) {
                const { booking_start } = this.parent; // Access the start date from the parent object
                if (!value || !booking_start) return true; // Skip validation if any of the dates is empty
                const startDate = new Date(booking_start); // Parse the booking_start as a Date
                const endDate = new Date(value); // Parse the booking_end as a Date
                return endDate > startDate; // Validate that the end date is greater than the start date
              }
            );
        }
        return schema.notRequired();
      }),
      description: Yup.string()
        .required("Description is required")
        .min(20, "Description must be at least 20 characters"),
      bed_type_id: Yup.string().required("Bed type is required"),
      size: Yup.number()
        .required("Room size is required")
        .min(1, "Size must be greater than 0"),
      room_type_id: Yup.string().required("Room type is required"),
    })
  ),
});
