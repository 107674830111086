import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface CommonLayoutV2Props {
  currentHref: string;
  children: React.ReactNode;
  PropertyID?: string | null;
}

const CommonLayoutV2: FC<CommonLayoutV2Props> = ({
  currentHref,
  children,
  PropertyID,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!PropertyID) {
      navigate("/add-listing-1");
    }
  }, [PropertyID, navigate]);

  const pathArray = [
    { path: 1, type: "Description", href: "/add-listing-1" },
    { path: 2, type: "Price", href: "/add-listing-2" },
    { path: 3, type: "Images", href: "/add-listing-3" },
    { path: 4, type: "Details", href: "/add-listing-4" },
    { path: 5, type: "Location", href: "/add-listing-5" },
    { path: 6, type: "Amenities", href: "/add-listing-6" },
    { path: 7, type: "Calendar", href: "/add-listing-7" },
    { path: 8, type: "Rooms", href: "/add-listing-8" },
  ].map((step) => {
    if (PropertyID) {
      return {
        ...step,
        href: `${step.href}?id=${PropertyID}`,
      };
    }
    return step;
  });

  const activeStepIndex = pathArray.findIndex((step) =>
    currentHref.startsWith(step.href.split("?")[0])
  );

  return (
    <div
      className="nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32"
      data-nc-id="PageAddListing1"
    >
      <div className="mb-10 mx-auto mt-auto pl-2 listingSection__wrap">
        <div className="relative flex flex-wrap sm:flex-nowrap w-full justify-center sm:justify-start gap-y-4 sm:w-3/4 md:w-1/2 lg:w-1/3">
          {pathArray.map((step, index) => (
            <div
              key={step.path}
              className="flex flex-col items-center w-[20%] sm:w-auto relative"
            >
              <div className="flex items-center justify-center flex-col">
                <a
                  href={step.href}
                  className={`w-2 h-2 rounded-full flex items-center justify-center mb-1 z-10 ${
                    index <= activeStepIndex
                      ? "bg-orange-500"
                      : "bg-gray-300 dark:bg-gray-700"
                  }`}
                ></a>
                {index < pathArray.length - 1 && (
                  <div
                    className={`absolute top-1 left-full transform -translate-x-1/2 h-[2px] w-[60%] sm:w-3/4 ${
                      index < activeStepIndex
                        ? "bg-orange-500"
                        : "bg-gray-300 dark:bg-gray-700"
                    }`}
                  ></div>
                )}
              </div>
              <a
                href={step.href}
                className={`text-xs text-center mt-2 w-[60px] sm:w-[90px] font-semibold ${
                  index === activeStepIndex
                    ? "text-orange-500 dark:text-orange-400"
                    : "text-gray-700 dark:text-gray-300"
                } hover:text-orange-500 dark:hover:text-orange-400`}
              >
                {step.type}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="listingSection__wrap">{children}</div>
    </div>
  );
};

export default CommonLayoutV2;
