import React, { useState, useEffect, useRef } from "react";
import "react-chat-elements/dist/main.css";
import { sendMessage } from "../../redux/reducers/UserSlice/UserSlice";
import { getMessage } from "../../redux/reducers/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import ChatList from "./Chat";
import { getChatLists } from "../../redux/reducers/ChatReducer/ChatSlice";
import { Message } from "../../redux/reducers/ChatReducer/ChatSliceTypes";
import { updateBookingsQueryDetails } from "../../redux/reducers/Properties/PropertiesSlice";
import MessageItem from "./components/MessageItem";
import moment from "moment";
import { resetCount } from "../../redux/reducers/ChatReducer/ChatSlice"; // Import resetCount action
import { useNavigate } from "react-router-dom";

const Messages = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [selectChat, setSelectedChat] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [booking, setBooking] = useState<Message[]>([]);
  const [bookingQuery, setBookingQuery] = useState<Message[] | null>(null);
  const [bookingQueryId, setBookingQueryId] = useState<any>([]);
  const [bookingId, setBookingId] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [isMessageSend, setIsMessageSend] = useState<boolean>(false);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const chatLists = useSelector((state: RootState) => state.chat.chatLists);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [filter, setFilter] = useState<"all" | "unread">("all");
  const [canProceedToPayment, setCanProceedToPayment] = useState(false);
  const navigate = useNavigate();
  const [filePreviews, setFilePreviews] = useState<{
    [chatId: number]: string | null;
  }>({});
  const extractedChat: any = chatLists.chats?.find(
    (item) => item?.id === selectChat?.id
  );
  console.log("chatLists", chatLists);
  const date = moment(new Date()).format("YYYY-MM-DD");
  const handleSendMessage = async () => {
    if (!message.trim() && attachments.length === 0) {
      return;
    }

    if (selectChat?.id) {
      const formData = new FormData();
      formData.append("message[body]", message ? message : "");
      formData.append("chat_id", selectChat.id);

      attachments.forEach((attachment, index) => {
        formData.append(`message[attachments][]`, attachment);
      });

      const action = await dispatch(sendMessage(formData));
      if (action?.payload?.success) {
        const newMessages = messages.map((item) =>
          item.date === date
            ? {
                date: date,
                messages: [...item.messages, action?.payload.message],
              }
            : item
        );
        setMessages(newMessages);
        setMessage("");
        setAttachments([]);
        setFilePreviews((prev) => ({
          ...prev,
          [selectChat.id]: null, // Reset preview for the current chat
        }));

        setTimeout(() => {
          setIsMessageSend(false);
        }, 400);
        setIsMessageSend(true);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && selectChat?.id) {
      // Set preview based on file type
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreviews((prev) => ({
            ...prev,
            [selectChat.id]: reader.result as string,
          }));
        };
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        setFilePreviews((prev) => ({
          ...prev,
          [selectChat.id]: "pdf",
        }));
      } else if (file.type.startsWith("video/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreviews((prev) => ({
            ...prev,
            [selectChat.id]: reader.result as string,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        setFilePreviews((prev) => ({
          ...prev,
          [selectChat.id]: null,
        }));
      }
      setAttachments([file]);
    }
  };

  const handleRemovePreview = (chatId: string) => {
    setFilePreviews((prev) => {
      const updatedPreviews = { ...prev };
      delete updatedPreviews[selectChat.id];
      return updatedPreviews;
    });
  };

  const extractDatesFromBody = (body: string) => {
    const dateRegex = /\b(?:\d{4}-\d{2}-\d{2}|\d{1,2}\/\d{1,2}\/\d{4})\b/g; // Matches dates like YYYY-MM-DD or MM/DD/YYYY
    const matches = body.match(dateRegex);

    if (matches && matches.length >= 2) {
      return {
        checkIn: moment(matches[0]).format("MMMM D, YYYY"),
        checkOut: moment(matches[1]).format("MMMM D, YYYY"),
      };
    }
    return null;
  };

  const [extractedDates, setExtractedDates] = useState<{
    checkIn: string;
    checkOut: string;
  } | null>(null);

  useEffect(() => {
    if (selectChat?.id) {
      setFilePreviews((prev) => ({
        ...prev,
        [selectChat.id]: null, // Reset preview when switching chats
      }));
    }
  }, [selectChat]);

  useEffect(() => {
    // Extract all bodies from the messages
    const allBodies = messages.flatMap((entry) =>
      entry.messages.map((msg) => msg.body)
    );

    // Extract dates from each body
    const dates = allBodies
      .map((body) => extractDatesFromBody(body))
      .filter((dates) => dates !== null);
    if (dates.length > 0) {
      setExtractedDates(dates[0]);
    }
  }, [messages]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
    console.log("booking", booking);
  }, [messages, booking]);

  const fetchChatLists = async () => {
    try {
      await dispatch(getChatLists());
    } catch (error) {
      console.log("Error in fetching host chat");
    }
  };

  const getAllMessages = async (chatId: number) => {
    const action = await dispatch(getMessage(chatId));
    if (action?.payload?.success) {
      setMessages(action?.payload?.message);

      // Access and handle booking
      const booking = action?.payload?.chat?.booking;
      if (booking) {
        console.log("Booking:", booking);
        const bookingId = booking?.id; // Safe access for booking ID
        console.log("Booking ID:", bookingId);

        // Set booking and bookingId in state
        setBooking(booking);
        setBookingId(bookingId);
      } else {
        console.log("No booking found.");
        //setBooking(null); // Reset booking state if not available
        setBookingId(null); // Reset bookingId state if not available
      }

      // Access and handle bookingQuery
      const bookingQuery = action?.payload?.chat?.booking_query;
      if (bookingQuery) {
        console.log("BookingQuery:", bookingQuery);
        const bookingQueryId = bookingQuery?.id; // Safe access for bookingQuery ID
        console.log("BookingQuery ID:", bookingQueryId);

        // Set bookingQuery and bookingQueryId in state
        setBookingQuery(bookingQuery);
        setBookingQueryId(bookingQueryId);
      } else {
        console.log("No booking_query found.");
        setBookingQuery(null); // Reset bookingQuery state if not available
        setBookingQueryId(null); // Reset bookingQueryId state if not available
      }
    }
  };

  const handleProceedToBooking = () => {
    if (bookingQuery != null) {
      const payload = {
        id: bookingQueryId,
        propertyId: selectChat.property.id,
        state: "accepted",
        check_in_date: extractedDates?.checkIn,
        check_out_date: extractedDates?.checkOut,
      };
      // Dispatch the action to update the booking status
      dispatch(updateBookingsQueryDetails(payload))
        .then((response) => {
          console.log("Booking updated successfully", response);
        })
        .catch((error) => {
          console.log("Error in updating booking", error);
        });
    } else {
      console.log("bookingQuery is null or id is missing");
    }
  };

  const handleProceedToPayment = () => {
    try {
      // Navigate directly to the booking details page
      navigate(`/booking_request?id=${bookingId}`);
    } catch (error) {
      console.error("Error during navigation:", error);
      alert("An error occurred while navigating. Please try again later.");
    }
  };

  useEffect(() => {
    fetchChatLists();
  }, [isMessageSend]);

  const getName = (name: string) => {
    const modifiedName = name[0]?.toUpperCase() + name?.slice(1);
    return modifiedName;
  };

  const filterChats = (chats: any[]) => {
    if (filter === "unread") {
      return chats.filter((chat) => chat.unreadCount > 0);
    }
    return chats;
  };
  const resetSelectedChatUnreadCount = () => {
    if (selectChat?.id) {
      dispatch(resetCount(selectChat.id)); // Dispatch resetCount for the selected chat
    }
  };

  const handleFilterChange = (newFilter: "all" | "unread") => {
    setFilter(newFilter);

    if (newFilter === "all") {
      resetSelectedChatUnreadCount(); // Only reset the unread count for the selected chat
    }
  };

  return (
    <div>
      <div className="flex h-screen bg-white dark:bg-neutral-900">
        {/* Sidebar */}
        <div className="w-1/4 border-r bg-white dark:bg-neutral-800 dark:border-neutral-700">
          <div className="px-4 py-6">
            <h2 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100">
              Messages
            </h2>

            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => handleFilterChange("all")}
                className={`px-7 py-2 h-12 rounded-3xl border-2 ${
                  filter === "all"
                    ? "bg-black text-white border-black"
                    : "bg-white dark:bg-neutral-700 text-neutral-900 dark:text-neutral-100 border-neutral-300 dark:border-neutral-600"
                }`}
              >
                All
              </button>
              <button
                onClick={() => handleFilterChange("unread")}
                className={`px-3 py-2 h-12 rounded-3xl border-2 ${
                  filter === "unread"
                    ? "bg-black text-white border-black"
                    : "bg-white dark:bg-neutral-700 text-neutral-900 dark:text-neutral-100 border-neutral-300 dark:border-neutral-600"
                }`}
              >
                Unread
              </button>
            </div>

            {/* Message Item */}
            <div className="mt-6">
              <ChatList
                currentUser={currentUser}
                chats={filterChats(chatLists?.chats)}
                selectChat={selectChat}
                setSelectedChat={setSelectedChat}
                getAllMessages={getAllMessages}
              />
            </div>
          </div>
        </div>

        {/* Main Chat Section */}
        {selectChat ? (
          <React.Fragment>
            <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
              <div className="px-6 py-4 flex justify-between items-center border-b border-neutral-300 dark:border-neutral-700">
                {/* Header */}
                <div className="flex items-center">
                  <img
                    src={
                      selectChat?.show?.image
                        ? selectChat?.show?.image
                        : "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                    }
                    alt="Profile"
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                      {getName(selectChat?.show?.name)}
                    </h3>
                  </div>
                </div>
              </div>

              {/* Messages */}
              <div
                ref={messageContainerRef}
                className="flex-1 relative overflow-y-auto px-6 py-6 space-y-4 text-center"
              >
                {/* Messages */}
                <div className="flex-1 overflow-y-auto px-6 py-6 space-y-4">
                  <div className="chat-container">
                    {messages?.map((msg: any) => {
                      return (
                        <MessageItem
                          message={msg}
                          currentUser={currentUser}
                          selectChat={selectChat} // Pass selectChat here
                          getName={getName}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* Input */}

              {/* <div
                className="asolute bottom-0 left-0 w-full bg-white dark:bg-neutral-800 px-6 py-4"
                style={{ zIndex: 10 }} // Ensure it's above other elements
              > */}
              {/* <div className="flex items-center">
                <div className="flex flex-col space-y-2 items-center">
                  {selectChat?.id && filePreviews[selectChat.id] && (
                    <div className="mr-4">
                      {filePreviews[selectChat.id] === "pdf" ? (
                        <div className="text-neutral-500 dark:text-neutral-300">
                          PDF File
                        </div>
                      ) : filePreviews[selectChat.id]?.startsWith(
                          "data:image"
                        ) ? (
                        <img
                          src={filePreviews[selectChat.id] as string}
                          alt="Preview"
                          className="w-16 h-16 object-cover"
                        />
                      ) : filePreviews[selectChat.id]?.startsWith(
                          "data:video"
                        ) ? (
                        <video
                          src={filePreviews[selectChat.id] as string}
                          controls
                          className="w-16 h-16 object-cover"
                        />
                      ) : null}
                    </div>
                  )}

                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className="text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200 mr-4"
                  >
                    📎
                  </button>
                  </div>
                  <div className="relative w-full">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type a message"
                      className="w-full p-3 pr-12 bg-white dark:bg-white border border-neutral-400 dark:border-neutral-600 rounded-4xl text-neutral-900 dark:text-neutral-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                    />
                    <input
                      type="file"
                      multiple={false}
                      ref={fileInputRef}
                      // onChange={(e) => {
                      //   if (e.target.files) {
                      //     setAttachments(Array.from(e.target.files));
                      //   }
                      // }}
                      onChange={handleFileChange}
                      className="hidden"
                      accept=".pdf,.png,.jpeg,.jpg,.doc,.docx,.mp4"
                    />
                  </div>
                  <button
                    onClick={handleSendMessage}
                    className="ml-4 bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:focus:ring-gray-300"
                  >
                    Send
                  </button>
                </div> */}
              <div
                className="bg-white dark:bg-neutral-800 px-6 py-4"
                style={{ zIndex: 10 }}
              >
                <div className="flex flex-col space-y-2">
                  {selectChat?.id && filePreviews[selectChat.id] && (
                    <div className="relative flex-shrink-0 w-20 h-20 border border-neutral-300 rounded-md overflow-hidden mb-2 bg-gray-200">
                      {filePreviews[selectChat.id]?.startsWith("data:image") ? (
                        <img
                          src={filePreviews[selectChat.id] as string}
                          alt="Preview"
                          className="w-full h-full object-cover"
                        />
                      ) : filePreviews[selectChat.id]?.startsWith(
                          "data:video"
                        ) ? (
                        <video
                          src={filePreviews[selectChat.id] as string}
                          controls
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="text-neutral-500 dark:text-neutral-300 flex items-center justify-center h-full">
                          PDF File
                        </div>
                      )}
                      <button
                        onClick={() => handleRemovePreview(selectChat.id)}
                        className="absolute top-0 right-0 bg-transparent text-white rounded-full w-5 h-5 flex items-center justify-center text-sm focus:outline-none"
                      >
                        ✖
                      </button>
                    </div>
                  )}

                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200 mr-4"
                    >
                      📎
                    </button>

                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type a message"
                      className="flex-grow bg-white dark:bg-neutral-900 border border-neutral-400 dark:border-neutral-600 rounded-lg p-3 focus:outline-none text-neutral-900 dark:text-neutral-100"
                    />
                    <input
                      type="file"
                      multiple={false}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="hidden"
                      accept=".pdf,.png,.jpeg,.jpg,.doc,.docx,.mp4"
                    />
                    <button
                      onClick={handleSendMessage}
                      className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:focus:ring-gray-300"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Reservation Section */}
            <div className="w-1/4 border-l border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                  Reservation
                </h3>
              </div>

              <div>
                {extractedChat ? (
                  <div className="mt-8 p-4 border border-neutral-300 dark:border-neutral-700 rounded-lg bg-white dark:bg-neutral-800">
                    <div>
                      <img
                        // src={extractedChat.property.placeImages[7]}
                        // alt={selectChat.property.title}
                        // className="w-full h-auto rounded-lg mb-4"
                        // onError={(e) => e.currentTarget.src = "https://www.w3schools.com/w3images/lights.jpg"}
                        src={
                          extractedChat.property.placeImages[0] ||
                          "https://www.w3schools.com/w3images/lights.jpg"
                        }
                        alt={selectChat.property.title}
                        className="w-full h-auto rounded-lg mb-4 object-contain"
                        style={{ maxHeight: "500px" }}
                        onError={(e) => {
                          console.error(
                            "Image failed to load:",
                            e.currentTarget.src
                          );
                          e.currentTarget.src =
                            "https://www.w3schools.com/w3images/lights.jpg";
                        }}
                      />
                      <h4 className="text-lg font-semibold text-neutral-800 dark:text-neutral-100">
                        {selectChat.property.title}
                      </h4>
                      <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                        City: {selectChat.property.city}
                      </p>
                      <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                        Address: {selectChat.property.address}
                      </p>
                    </div>
                  </div>
                ) : (
                  <span className="text-neutral-900 dark:text-neutral-100">
                    No Property found
                  </span>
                )}
              </div>

              {currentUser?.is_student &&
                (booking === null ? (
                  // &&
                  // bookingQuery &&
                  // bookingQuery[1]?.messages?.[0]?.booking_query?.status !== "accepted" &&
                  // bookingQuery[1]?.messages?.[0]?.booking_query?.status !== "rejected"
                  <div className="mt-7 p-4 border-t border-neutral-400 dark:border-neutral-700">
                    <h4 className="text-lg font-semibold text-neutral-800 dark:text-neutral-100 mb-4">
                      Your booking is pending
                    </h4>
                    <div className="flex gap-4">
                      <button
                        className="px-6 py-3 bg-white text-black border-2 border-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none dark:bg-gray-800 dark:text-white"
                        onClick={handleProceedToBooking}
                      >
                        Proceed to Book
                      </button>
                    </div>
                  </div>
                ) : (
                  booking !== null && (
                    <div className="mt-7 p-4 border-t border-neutral-400 dark:border-neutral-700">
                      <div className="flex gap-4">
                        <button
                          className="px-6 py-3 bg-white text-black border-2 border-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none dark:bg-gray-800 dark:text-white"
                          onClick={handleProceedToPayment}
                        >
                          View Booking
                        </button>
                      </div>
                    </div>
                  )
                ))}
              {extractedDates && (
                <div className="mt-7 p-4 border-t border-neutral-400 dark:border-neutral-700">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col items-center">
                      <p className="font-bold text-lg text-neutral-800 dark:text-neutral-100">
                        Check-in
                      </p>
                      <p className="text-md text-neutral-500 dark:text-neutral-400">
                        {extractedDates.checkIn}
                      </p>
                    </div>

                    <div className="h-10 border-l border-neutral-400 dark:border-neutral-700 mx-4"></div>

                    <div className="flex flex-col items-center">
                      <p className="font-bold text-lg text-neutral-800 dark:text-neutral-100">
                        Check-out
                      </p>
                      <p className="text-md text-neutral-500 dark:text-neutral-400">
                        {extractedDates.checkOut}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="p-4 mt-2 border-t border-neutral-400 dark:border-neutral-700"></div>
            </div>
          </React.Fragment>
        ) : (
          <div className="w-screen h-screen flex items-center justify-center">
            <h3 className="text-neutral-900 dark:text-neutral-100">
              NO MESSAGE YET. START THE CONVERSATION!
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
