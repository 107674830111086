import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  createChat,
  bookingQueries,
} from "../../../redux/reducers/UserSlice/UserSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Room, booking } from "redux/reducers/UserSlice/UserSliceType";
import { toast } from "react-toastify";
import successHandler from "utils/helpers/SuccessHandler";

interface ContactOwnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: (data: booking) => void;
  propertyData?: any;
}

const ContactOwnerModal: React.FC<ContactOwnerModalProps> = ({
  isOpen,
  onClose,
  onSend,
  propertyData = {},
}) => {
  const property = useSelector((state: RootState) => state.property);
  const [available, setAvailable] = useState<Room[]>([]);
  const [showRoomNumber, setShowRoomNumber] = useState(true);
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );

  const router = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const propertyId = globalId || id;
  const isSharedProperty = propertyData.is_shared_property;
  const availableStart = propertyData.availability_start;
  const availabilityEnds = propertyData.availability_end;
  const rooms = propertyData?.rooms?.map((room: any, idx: number) => ({
    data: `Room ${idx + 1}`,
    value: room?.id,
  }));

  // const validationSchema = Yup.object({
  //   check_in_date: Yup.date()
  //   .required("Check In date is required")
  //   .test(
  //     "not-before-availableStart",
  //     "Check In date cannot be before available start date",
  //     (value) => value && new Date(value) >= new Date(availableStart)
  //   )
  //   .test(
  //     "not-after-availabilityEnds",
  //     "Check In date cannot be after availability end.",
  //     (value) => value && new Date(value) <= new Date(availabilityEnds)
  //   ),

  // check_out_date: Yup.date()
  //   .required("Check Out date is required")
  //   .test(
  //     "after-check-in",
  //     "Check Out date should be after Check In date",
  //     function (value) {
  //       const { check_in_date } = this.parent;
  //       return value && new Date(value) > new Date(check_in_date);
  //     }
  //   )
  //   .test(
  //     "not-before-availableStart",
  //     "Check Out date cannot be before available start date",
  //     (value) => value && new Date(value) >= new Date(availableStart)
  //   )
  //   .test(
  //     "not-after-availabilityEnds",
  //     "Check Out date cannot be after availability end",
  //     (value) => value && new Date(value) <= new Date(availabilityEnds)
  //   ),
  //   guest_count: Yup.number()
  //     .min(1, "At least one guest is required")
  //     .required("Number of guests is required"),
  //   query_for: Yup.string().nullable().when("isSharedProperty", {
  //     is: true,
  //     then: (schema) => schema.required("Please select a room"),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
  //   query: Yup.string().max(500, "Message must be 500 characters or less"),
  // });

  const formik = useFormik({
    initialValues: {
      check_in_date: "",
      check_out_date: "",
      guest_count: 1,
      query_for: "",
      query: "",
    },

    // validationSchema,
    onSubmit: async (values) => {
      const queryFor = values.query_for
        ? { room_number: [values.query_for] }
        : null;

      onSend(values);

      try {
        await dispatch(
          bookingQueries({
            ...values,
            query_for: queryFor,
            property_id: propertyId,
          })
        ).unwrap();
        successHandler(
          "Your message was sent! You will be notified by email when a reply is received."
        );
        onClose();
      } catch (error) {
        console.error("Failed to dispatch booking query:", error);
        toast.error("Failed to create chat. Please try again.");
      }

      onClose();
    },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 dark:bg-black-800 dark:bg-opacity-75 z-40 backdrop-blur-md">
      <div className="enquiry-modal bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 w-full max-w-md relative mt-16 z-50 mr-7">
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 focus:outline-none"
          onClick={onClose}
        >
          ×
        </button>
        <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2 text-center">
          Contact the owner
        </h2>
        <p className="text-gray-500 dark:text-gray-400 text-center mb-4">
          Please complete the form below to contact the owner.
        </p>

        <form onSubmit={formik.handleSubmit}>
          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Check In</span>
            <input
              type="date"
              name="check_in_date"
              value={formik.values.check_in_date}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-300"
            />
            {formik.touched.check_in_date && formik.errors.check_in_date && (
              <div className="text-red-500 text-sm">
                {formik.errors.check_in_date}
              </div>
            )}
          </label>

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Check Out</span>
            <input
              type="date"
              name="check_out_date"
              value={formik.values.check_out_date}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-300"
            />
            {formik.touched.check_out_date && formik.errors.check_out_date && (
              <div className="text-red-500 text-sm">
                {formik.errors.check_out_date}
              </div>
            )}
          </label>

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Guests</span>
            <select
              name="guest_count"
              value={formik.values.guest_count}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-300"
            >
              {Array.from({ length: 10 }, (_, n) => (
                <option key={n} value={n + 1}>
                  {n + 1} Guest
                </option>
              ))}
            </select>
            {formik.touched.guest_count && formik.errors.guest_count && (
              <div className="text-red-500 text-sm">
                {formik.errors.guest_count}
              </div>
            )}
          </label>

          {isSharedProperty && (
            <label className="block">
              <span className="text-gray-700 dark:text-gray-300">
                Room-Number
              </span>
              <select
                name="query_for"
                value={formik.values.query_for}
                onChange={formik.handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-300"
              >
                <option value="">Select number of rooms</option>
                {rooms.length > 0 ? (
                  rooms.map((room: any) => (
                    <option key={room.value} value={room.value}>
                      {room.data}
                    </option>
                  ))
                ) : (
                  <option disabled>No rooms available</option>
                )}
              </select>
              {formik.touched.query_for && formik.errors.query_for && (
                <div className="text-red-500 text-sm">
                  {formik.errors.query_for}
                </div>
              )}
            </label>
          )}

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Query</span>
            <textarea
              name="query"
              value={formik.values.query}
              onChange={formik.handleChange}
              placeholder="Your message"
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-300"
            />
            {formik.touched.query && formik.errors.query && (
              <div className="text-red-500 text-sm">{formik.errors.query}</div>
            )}
          </label>

          <button
            type="submit"
            className="w-full py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 dark:focus:ring-offset-gray-900 dark:bg-orange-400 dark:hover:bg-orange-500 mt-4"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactOwnerModal;
