import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilterV2 from "./HeaderFilterV2";
import StayCard from "components/StayCard/StayCard";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllProperties } from "../../redux/reducers/Properties/PropertiesSlice";

// OTHER DEMO WILL PASS PROPS
const DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i);

//
export interface SectionGridFeaturePlacesV2Props {
    stayListings?: StayDataType[];
    gridClass?: string;
    heading?: ReactNode;
    subHeading?: ReactNode;
    headingIsCenter?: boolean;
    tabs?: string[];
    pagemetaData?: any
}
const SectionGridFeaturePlacesV2: FC<SectionGridFeaturePlacesV2Props> = ({
    stayListings = DATA,
    gridClass = "",
    heading = "Booking Query",
    subHeading = "Popular places to stay that Chisfis recommends for you",
}) => {
    const renderCard = (stay: StayDataType) => {
        return <StayCard key={stay.id} data={stay} />;
    };
    const dispatch = useDispatch<AppDispatch>();
    const [page, setPage] = useState(1);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingPrev, setLoadingPrev] = useState(false);


    const { loading, allProperties, metaData } = useSelector(
        (state: RootState) => state.property
    );
    const handleLoadMore = async () => {
        if (metaData?.next_page) {
            setLoadingNext(true);
            await dispatch(getAllProperties(page + 1));
            setPage((prevPage) => prevPage + 1);
            setLoadingNext(false);
        }
    };



    const handleGoBack = async () => {
        if (metaData?.prev_page && page > 1) {
            setLoadingPrev(true);
            await dispatch(getAllProperties(page - 1));
            setPage((prevPage) => prevPage - 1);
            setLoadingPrev(false);
        }
    };
    useEffect(() => {
        dispatch(getAllProperties(page));
    }, [dispatch, page]);

    return (
        <div className="nc-SectionGridFeaturePlaces relative">
            <HeaderFilterV2
                subHeading={subHeading}
                heading={heading}
            />
            <div
                className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
            >
                {stayListings?.map((stay) => renderCard(stay))}
            </div>
        </div>
    );
};

export default SectionGridFeaturePlacesV2;
