export const ENDPOINTS = {
  SIGN_IN: "/users/tokens/sign_in",
  SIGN_UP: "/users/tokens/sign_up",
  SOCIAL_SIGNING: "/api/v1/users/socials/login",
  UPDATE_USER: "/api/v1/users/profile",
  UPDATE_USER_EMAIL_AS_VERFIED: "/api/v1/users/profile/sign_in_count",
  LOGGGED_IN: "/users/tokens/info",
  GET_ALL_PROPERTIES: (page: number) => `/api/v1/properties?page=${page}`,
  UPDATE_NOTIFICATION_PREFERENCE: "api/v1/users/profile/set_preference",
  CREATE_PROPRTIES: "/api/v1/properties",
  PROPERTY_CATEGORY: "/api/v1/property_categories",
  UPDATE_PROPERTIES: "/api/v1/properties",
  AMENITIES_PROPERTIES: "/api/v1/amenities/property",
  HOUSE_RULE: "api/v1/house_rules",
  DELETE_USER: "/api/v1/users",
  GET_ALL_UPDATE_PROPERTY_DETAILS: "/api/v1/properties",
  SEND_EMAIL: "users/password",
  ADD_CREDIT_CARD: "api/v1/credit_cards",
  ADD_PAYPAL: "/api/v1/user_paypal",
  EDIT_PAYPAL: "/api/v1/user_paypal",
  CREATE_CHAT: "api/v1/chats",
  SEND_MESSAGE: (chatId: number) => `/api/v1/chats/${chatId}/messages`,
  GET_MESSAGE: (chatId: number) => `/api/v1/chats/${chatId}/messages`,
  DELETE_MESSAGE: (chatId: number,messageId: number) => `/api/v1/chats/${chatId}/messages/${messageId}`,
  ADD_QUERIES: (propertyId: number) =>
    `/api/v1/properties/${propertyId}/booking_queries`,
  SEARCH_PROPERTIES: (query: string) => `/api/v1/properties/search?${query}`,
  BOOKING: "/api/v1/bookings",
  PAYMET_INTENT: "/api/v1/payments/payment_intent",
  PAYMENT_METHODS: "/api/v1/payments/payment_method",
  PAYMENT_SUCCESS: (bookingId: number) => `/api/v1/bookings/${bookingId}`,
  GET_ALL_PROPERTY_TYPE: "/api/v1/property_types",
  GET_ALL_FEATURES_STATUS: "api/v1/features/property",
  GET_TAX: "/api/v1/properties/property_tax",
  ROOM_AMENITIES_PROPERTIES: "/api/v1/amenities/room",
  ROOM_FEATURES_PROPERTIES: "/api/v1/features/room",
  ROOM_BED_TYPE: "api/v1/bed_types",
  ROOM_TYPE: "api/v1/room_types",
  HOST_CHAT_LISTS: "/api/v1/chats",
  STUDENT_CHAT_LISTS: "/api/v1/chats/user_chat",
  GET_PROPERTY_Listing: (page: number, query: string) =>
    `/api/v1/properties/my_properties?page=${page}&q[title_cont]=${query?.trim()}`,
  BOOKING_DEATAILS: (page: number) =>
    `/api/v1/bookings/my_reservation?page=${page}`,
  GET_BOOKING_DEATAILS: "/api/v1/bookings",
  MANAGE_REQUEST_BOOKING: (page: number) =>
    `api/v1/bookings/host_request?page=${page}`,
  MANAGE_QUERIES_BOOKING: "api/v1/booking_queries/host_query",
  GET_BOOKING_QUERIES_DATA: "/api/v1/booking_queries",
  BOOKING_QUERY: (id: number, propertyId: number) =>
    `/api/v1/booking_queries/${id}?property_id=${propertyId}`,
  GET_BOOKING_QUERIES: (page: number) => `/api/v1/booking_queries?page=${page}`,
  HOST_BOOKING_DETAILS: "/api/v1/bookings/host_booking",
  GET_CANCILATION_DETAILS: "/api/v1/cancellation_policies",
  UPDATE_PROPERTY_LISTING: "/api/v1/properties",
  DELETEIMAGE: (id: number) => `/api/v1/image_destroy?id=${id}`,
};
