import React, { useEffect, useRef, useState } from 'react';

// Update the interface to match your DateRangePicker type
interface DateRangePicker {
    startDate: any;
    endDate: any;
}

interface DateSelectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: (dates: any) => any;
}

const DateSelectionModal: React.FC<DateSelectionModalProps> = ({
    isOpen,
    onClose,
    onSubmit
}) => {
    const [dates, setDates] = useState<DateRangePicker>({
        startDate: null,
        endDate: null
    });

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscKey);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, onClose]);

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(dates);
        }
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                aria-hidden="true"
            />

            <div
                ref={modalRef}
                className="relative z-50 w-full max-w-md p-6 mx-4 bg-white rounded-lg shadow-xl transform transition-all"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-title"
            >
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                >
                    <span className="sr-only">Close</span>
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                <div className="mb-5">
                    <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                    >
                        Change Requested Dates
                    </h3>
                </div>

                <div className="grid gap-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                                Check In
                            </label>
                            <div className="relative">
                                <input
                                    type="date"
                                    className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    onChange={(e) => setDates(prev => ({
                                        ...prev,
                                        startDate: e.target.valueAsDate
                                    }))}
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                                Check Out
                            </label>
                            <div className="relative">
                                <input
                                    type="date"
                                    className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    onChange={(e) => setDates(prev => ({
                                        ...prev,
                                        endDate: e.target.valueAsDate
                                    }))}
                                />
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={handleSubmit}
                        className="w-full px-4 py-2 mt-4 text-white bg-red-400 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                        Change Requested Dates
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DateSelectionModal;