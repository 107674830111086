import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { convertToDate } from "utils/commonMethods";
import CommonLayoutV2 from "./CommonLayoutV2";
import "../../../src/shared/DateField/CustomDatePicker.scss";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import FormItem from "./FormItem";

export interface ListingCalenderProps {}

const ListingCalender: FC<ListingCalenderProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [availabilityStart, setAvailabilityStart] = useState<Date | null>(null);
  const [availabilityEnd, setAvailabilityEnd] = useState<Date | null>(null);
  const [excludedDates, setExcludedDates] = useState<
    { start: Date; end: Date }[]
  >([]);

  const RouteID = globalId || id;

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setAvailabilityStart(start);
    setAvailabilityEnd(end);
  };

  const formik = useFormik({
    initialValues: {
      unlimited_availability: false,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload: any = {
        id: globalId || id,
        availability_start: availabilityStart?.toLocaleDateString(),
        availability_end: formik.values.unlimited_availability
          ? null
          : availabilityEnd?.toLocaleDateString(),
        unlimited_availability: values.unlimited_availability,
      };

      setSubmitting(true);
      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response: any) => {
          if (response) {
            successHandler(response?.message || "Updated successfully");
            resetForm();
            navigate(`/add-listing-8?id=${response.property.id}`);
          }
        })
        .catch((error: any) => {
          console.error("Error updating property: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  
  console.log("availability_start",availabilityStart)

  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response: any) => {
          if (response.success && response.property) {
            const {
              availability_start,
              availability_end,
              minimum_months_of_booking,
              unlimited_availability,
            } = response.property;

            const startDate = convertToDate(availability_start || Date());
            const endDate = convertToDate(availability_end || Date());

            setAvailabilityStart(startDate);
            setAvailabilityEnd(endDate);

            formik.setFieldValue(
              "unlimited_availability",
              unlimited_availability
            );

            if (minimum_months_of_booking) {
              const today = new Date();
              const excludedStart = new Date(
                today.getFullYear(),
                today.getMonth() + minimum_months_of_booking,
                today.getDate()
              );

              setExcludedDates([{ start: today, end: excludedStart }]);
            }
          }
        })
        .catch((error: any) => {
          console.error("Error fetching property: ", error);
        });
    }
  }, [dispatch, id]);

  return (
    <CommonLayoutV2 currentHref="/add-listing-7" PropertyID={RouteID}>
      <div>
        <h2 className="text-2xl font-semibold">Set your availability</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Shorter trips can mean more reservations, but you'll turn over your
          space more often.
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      {/* FORM */}
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-7">
          <div>
            {/* <h2 className="text-2xl font-semibold">Set your availability</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Select start and end dates to set availability, or type custom
              dates.
            </span> */}
            <div className="flex items-center justify-between p-2">
              <div className="text-lg font-medium">Availability</div>
              <FormItem label="">
                <CheckboxV2
                  name="unlimited_availability"
                  label="Unlimited Availability"
                  onChange={(checked) =>
                    formik.setFieldValue("unlimited_availability", checked)
                  }
                  checked={formik.values.unlimited_availability}
                />
              </FormItem>
            </div>
            <div
              className={`relative flex-shrink-0 flex justify-center z-10 py-5`}
            >
              <DatePicker
                selected={availabilityStart}
                onChange={onChangeDate}
                startDate={availabilityStart}
                endDate={availabilityEnd}
                selectsRange
                monthsShown={2}
                showPopperArrow={false}
                minDate={new Date()}
                excludeDateIntervals={formik.values.unlimited_availability ? [] :excludedDates}
                className="custom-datepicker"
                inline
                renderCustomHeader={(props) => (
                  <DatePickerCustomHeaderTwoMonth {...props} />
                )}
                renderDayContents={(day, date) => (
                  <DatePickerCustomDay dayOfMonth={day} />
                )}
                dayClassName={(date) => {
                  if (formik.values.unlimited_availability) {
                    const today = new Date();
                    const nextYear = new Date();
                    nextYear.setFullYear(today.getFullYear() + 15);
                    if (date >= today && date <= nextYear) {
                      return "blue-date";
                    }
                  }
                  return null;
                }}
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-5 mt-8">
          <ButtonSecondary href={`/add-listing-6?id=${globalId || id}`}>
            Go back
          </ButtonSecondary>
          <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Loading..." : "Continue"}
          </ButtonPrimary>
        </div>
      </form>
    </CommonLayoutV2>
  );
};

export default ListingCalender;
