import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/Description";
import PageAddListing2 from "containers/PageAddListing1/Price";
import PageAddListing from "containers/PageAddListing1/PageAddListing";
// import PageAddListing3 from "containers/PageAddListing1/PageAddListing66";
// import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing3 from "containers/PageAddListing1/Image";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import Rooms from "containers/PageAddListing1/Rooms";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingStayDetailPageV2 from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPageV2";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import AuthWrapper from "./AuthWrapper";
import Messages from "containers/Messages/Messages";
import RedirectIfAuthenticatedWrapper from "./RedirectIfAuthenticatedWrapper";
import ForgetPassword from "containers/ForgetPassword/ForgetPassword";
import RequestPasswordReset from "containers/RequestChangePassword/RequestPasswordReset";
import Booking from "containers/Booking/Booking";
import PaymentSuccess from "containers/Payment/PaymentSuccess";
import PageAddListing13 from "containers/PageAddListing1/PageAddListing13";
import PageAddListing12 from "containers/PageAddListing1/PageAddListing12";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import BookingRequest from "containers/BookingRequest/BookingRequest";
import MyListing from "containers/ListingDetailPage/listing-stay-detail/MyListing";
import Invoice from "containers/Booking/Invoice";
import ManageBookings from "containers/ListingDetailPage/listing-stay-detail/ManageBookings";
import MyRequest from "containers/ListingDetailPage/listing-stay-detail/MyRequest";
import bookingQuery from "containers/ListingDetailPage/listing-stay-detail/BookingQuery";
import BookingQueryDetails from "containers/ListingDetailPage/listing-stay-detail/BookingQueryDetails";
import Location from "containers/PageAddListing1/Location";
import Amentites from "containers/PageAddListing1/Amentites";
import Calender from "containers/PageAddListing1/ListingCalender";
import Image from "containers/PageAddListing1/Image";
import Price from "containers/PageAddListing1/Price";
import Description from "containers/PageAddListing1/Description";
import PropertyList from "containers/PageAddListing1/PropertyList";
import ListingDetails from "containers/PageAddListing1/ListingDetails";
import ListingCalender from "containers/PageAddListing1/ListingCalender";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome, isPublic: false },
  { path: "/#", exact: true, component: PageHome, isPublic: false },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome3 },
  //
  { path: "/listing-stay", component: ListingStayPage, isPublic: false },
  { path: "/listing-stay-map", component: ListingStayMapPage, isPublic: false },
  {
    path: "/listing-stay-detail",
    component: ListingStayDetailPage,
    isPublic: false,
  },
  // {
  //   path: "/listing-stay-detail",
  //   component: ListingStayDetailPageV2,
  //   isPublic: false,
  // },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
    isPublic: false,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
    isPublic: false,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
    isPublic: false,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  { path: "/my_propeties", component: MyListing },
  { path: "/booking_queries", component: bookingQuery },

  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage, isPublic: false },
  { path: "/pay-done", component: PayPage, isPublic: false },
  { path: "/booking_request", component: BookingRequest, isPublic: false },
  { path: "/manage_bookings", component: ManageBookings, isPublic: false },
  { path: "/my_bookings", component: MyRequest, isPublic: false },
  { path: "/booking_queries_details", component: BookingQueryDetails, isPublic: false },

  //
  { path: "/author", component: AuthorPage, isPublic: false },
  { path: "/account", component: AccountPage, isPublic: false },
  { path: "/account-password", component: AccountPass, isPublic: false },
  { path: "/account-savelists", component: AccountSavelists, isPublic: false },
  { path: "/account-billing", component: AccountBilling, isPublic: false },
  { path: "/messages", component: Messages, isPublic: false },
  { path: "/booking", component: Booking, isPublic: false },
  { path: "/success", component: PaymentSuccess, isPublic: false },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: Description, isPublic: false },
  { path: "/add-listing-2", component: Price, isPublic: false },
  { path: "/add-listing-3", component: Image, isPublic: false },
  { path: "/add-listing-4", component: ListingDetails, isPublic: false },
  { path: "/add-listing-5", component: Location, isPublic: false },
  { path: "/add-listing-6", component: Amentites, isPublic: false },
  { path: "/add-listing-7", component: ListingCalender, isPublic: false },
  { path: "/add-listing-8", component: Rooms, isPublic: false }, 
  { path: "/add-listing-9", component: PropertyList, isPublic: false },
  { path: "/add-listing-19", component: PageAddListing7, isPublic: false },
  //
  { path: "/contact", component: PageContact, isPublic: true },
  { path: "/about", component: PageAbout, isPublic: true },
  { path: "/signup", component: PageSignUp, isPublic: true },
  { path: "/login", component: PageLogin, isPublic: true },
  { path: "/subscription", component: PageSubcription, isPublic: true },
  { path: "/forgot_password", component: ForgetPassword, isPublic: true },
  { path: "/edit_password", component: RequestPasswordReset, isPublic: true },

  //
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path, isPublic }) => {
          const Component = component;
          if (!isPublic) {
            // Wrap protected routes with AuthWrapper
            return (
              <Route
                key={path}
                path={path}
                element={
                  <AuthWrapper>
                    <Component />
                  </AuthWrapper>
                }
              />
            );
          } else {
            // Check if the route is Login or Signup to apply RedirectIfAuthenticated
            if (path === "/login" || path === "/signup") {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <RedirectIfAuthenticatedWrapper>
                      <Component />
                    </RedirectIfAuthenticatedWrapper>
                  }
                />
              );
            }
            // Public routes without any wrapper
            return <Route key={path} path={path} element={<Component />} />;
          }
        })}

        {/* Catch-all route for 404 page */}
        <Route path="*" element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
