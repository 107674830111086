import React, { FC, useEffect, useState } from "react";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { Formik, Form } from "formik";
import {
  getallupdateProperties,
  getAmenitiesProperty,
  getFeaturesProperty,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { amenitiesvalidationSchema } from "utils/formSchema";
import CommonLayoutV2 from "./CommonLayoutV2";

export interface AmentitesProps {}

const Amentites: FC<AmentitesProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedAmenities, setSelectedAmenities] = useState<any[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);
  const navigate = useNavigate();
  const { globalId, amenitiesCategories, featuresCategories } = useSelector(
    (state: RootState) => state.property
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const  RouteID = globalId || id;
  const amenitiesIds = selectedAmenities?.map((item: any) => item.amenity_id);
  const featuresIds = selectedFeatures?.map((item: any) => item.feature_id);

  const initialValues = {
    amenities: [],
    features: [],
  };

  // Update the checkbox state for amenities
  const handleAmenitiesChange = (id: number) => {
    if (amenitiesIds?.includes(id)) {
      const remainingAmenities = selectedAmenities?.filter(
        (amenity: any) => amenity.amenity_id !== id
      );
      setSelectedAmenities(remainingAmenities);
    } else {
      const data = {
        amenity_id: id,
      };
      setSelectedAmenities([...selectedAmenities, data]);
    }
  };

  // Update the checkbox state for features
  const handleFeaturesChange = (id: number) => {
    if (featuresIds?.includes(id)) {
      const remainingFeatures = selectedFeatures?.filter(
        (feature: any) => feature.feature_id !== id
      );
      setSelectedFeatures(remainingFeatures);
    } else {
      const data = {
        feature_id: id,
      };
      setSelectedFeatures([...selectedFeatures, data]);
    }
  };

  const handleSubmit = (values: { amenities: any; features: any }) => {
    const payload = {
      id: globalId || id,
      property_amenities_attributes: selectedAmenities,
      property_features_attributes: selectedFeatures,
    };

    dispatch(updateProperties({ property: payload }))
      .unwrap()
      .then((response) => {
        successHandler(response?.message || "Updated successfully");
        navigate(`/add-listing-7?id=${response.property.id}`);
      })
      .catch((error) => {
        console.error("Error updating property: ", error);
      });
  };

  // Fetch amenities and features categories for the form
  useEffect(() => {
    dispatch(getAmenitiesProperty());
    dispatch(getFeaturesProperty());
  }, [dispatch]);

  // Load existing property data for editing (prefill form)
  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          const prefilledAmenities = (response?.property?.amenities || []).map(
            (amenity: any) => ({amenity_id : amenity.id})
          );

          const prefilledFeatures = (response?.property?.features || []).map(
            (feature: any) => ({feature_id : feature.id})
          );

          setSelectedAmenities(prefilledAmenities);
          setSelectedFeatures(prefilledFeatures);
        });
    }
  }, [dispatch, globalId, id]);

  return (
    <CommonLayoutV2 currentHref="/add-listing-6" PropertyID= {RouteID}>
      <>
        <div>
          <h2 className="text-2xl font-semibold">Amenities</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          // validationSchema={amenitiesvalidationSchema}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <div className="space-y-8">
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  {amenitiesCategories.length > 0 ? (
                    amenitiesCategories?.map((amenity) => (
                      <CheckboxV2
                        id={amenity?.id}
                        key={amenity?.id}
                        label={amenity?.name}
                        name={amenity?.name}
                        onChange={(checked) =>
                          handleAmenitiesChange(amenity?.id)
                        }
                        checked={amenitiesIds.includes(amenity?.id)}
                      />
                    ))
                  ) : (
                    <p className="text-neutral-500 dark:text-neutral-400">
                      No amenities available for this category.
                    </p>
                  )}
                </div>

                {/* Features Section */}
                <div>
                  <h2 className="text-2xl font-semibold mt-10">Features</h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    Many customers have searched for accommodation based on
                    features criteria
                  </span>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                </div>

                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  {featuresCategories?.map((feature: any) => (
                    <div key={feature?.id}>
                      <CheckboxV2
                        id={feature?.id}
                        label={feature?.name}
                        name={feature?.name}
                        onChange={(checked) =>
                          handleFeaturesChange(feature?.id)
                        }
                        checked={featuresIds.includes(feature?.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end space-x-5">
                <ButtonSecondary href={`/add-listing-5?id=${globalId || id}`}>
                  Go back
                </ButtonSecondary>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Continue"}
                </ButtonPrimary>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </CommonLayoutV2>
  );
};

export default Amentites;
