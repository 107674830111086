import React, { FC, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import {
  deleteImage,
  getallupdateProperties,
  updateImagesProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayoutV2 from "./CommonLayoutV2";

export interface ImageProps {}

const Image: FC<ImageProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);

  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [coverImagePreview, setCoverImagePreview] = useState<any | null>(null);
  const [placeImages, setPlaceImages] = useState<File[]>([]);
  const [placeImagesPreviews, setPlaceImagesPreviews] = useState<any[]>([]);
  const [propertySize, setPropertySize] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const RouteID = globalId || id;

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (!globalId && !id) {
        console.error("Global ID is not set");
        return;
      }

      const propertyId = globalId || id;
      const response: any = await dispatch(
        getallupdateProperties({ propertyId })
      );

      const property = response.payload?.property;

      if (property) {
        if (property.cover_image) {
          setCoverImagePreview(property.cover_image);
        }

        if (property.place_images) {
          setPlaceImagesPreviews(property.place_images);
        }

        setPropertySize(property.property_size || null);
      } else {
        console.error("Property not found:", property);
      }
    };

    fetchPropertyData();
  }, [dispatch, globalId, id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);

    const formData = new FormData();

    if (!globalId && !id) {
      console.error("Global ID is not set");
      return;
    }

    formData.append("property[id]", globalId || id?.toString());
    formData.append("property[property_size]", propertySize?.toString() || "");

    if (coverImage) {
      formData.append("property[cover_image]", coverImage);
    }

    placeImages.forEach((image) => {
      formData.append("property[place_images][]", image);
    });

    dispatch(updateImagesProperties(formData))
      .unwrap()
      .then((response) => {
        if (response?.success) {
          successHandler("Property images updated successfully!");
          navigate(`/add-listing-4?id=${response.property.id || globalId}`);
        }
      })
      .catch((error) => {
        console.error("Error updating property images: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCoverImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (typeof coverImagePreview === "string") {
        URL.revokeObjectURL(coverImagePreview);
      }
      setCoverImage(file);
      setCoverImagePreview(URL.createObjectURL(file));
    }
  };

  const handlePlaceImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const validImageFiles = filesArray.filter((file) =>
        file.type.startsWith("image/")
      );

      if (validImageFiles.length > 0) {
        setPlaceImages((prev) => [...prev, ...validImageFiles]);
        setPlaceImagesPreviews((prev) => [
          ...prev,
          ...validImageFiles.map((file) => URL.createObjectURL(file)),
        ]);
      } else {
        alert("Please select valid image files.");
      }
    }
  };

  const handleRemoveCoverImage = (id?: number) => {
    if (id) {
      dispatch(deleteImage({ id }))
        .then(() => {
          setCoverImage(null);
          setCoverImagePreview(null);
        })
        .catch((error) => console.error("Error removing image:", error));
    } else {
      if (typeof coverImagePreview === "string") {
        URL.revokeObjectURL(coverImagePreview);
      }
      setCoverImage(null);
      setCoverImagePreview(null);
    }
  };

  const handleRemovePlaceImage = (index: number, id?: number) => {
    if (!id) {
      setPlaceImages((prev) => {
        const newImages = [...prev];
        newImages.splice(index, 1);
        return newImages;
      });
      setPlaceImagesPreviews((prev) => {
        const newPreviews = [...prev];
        newPreviews.splice(index, 1);
        return newPreviews;
      });
      return;
    }
    dispatch(deleteImage({ id }))
      .unwrap()
      .then(() => {
        setPlaceImages((prev) => {
          const newImages = [...prev];
          newImages.splice(index, 1);
          return newImages;
        });
        setPlaceImagesPreviews((prev) => {
          const newPreviews = [...prev];
          newPreviews.splice(index, 1);
          return newPreviews;
        });
      })
      .catch((error) => {
        console.error("Error removing image:", error);
      });
  };

  return (
    <CommonLayoutV2 currentHref="/add-listing-3" PropertyID={RouteID}>
      <>
        <div>
          <h2 className="text-2xl font-semibold">Pictures of the place</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            A few beautiful photos will help customers have more sympathy for
            your property.
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <form onSubmit={handleSubmit}>
          <div className="space-y-8">
            <div>
              <span className="text-lg font-semibold">Cover image</span>
              <div className="mt-5">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-600 dark:text-neutral-300">
                      <label
                        htmlFor="cover-image-upload"
                        className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="cover-image-upload"
                          name="cover-image-upload"
                          type="file"
                          accept="image/*"
                          className="sr-only"
                          onChange={handleCoverImageChange}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>

                {coverImagePreview && (
                  <div className="mt-3 relative">
                    <img
                      src={
                        typeof coverImagePreview === "string"
                          ? coverImagePreview
                          : coverImagePreview.url
                      }
                      alt="Cover Preview"
                      className="rounded-md w-30 h-30 object-cover"
                      style={{ width: "120px", height: "120px" }}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleRemoveCoverImage(coverImagePreview?.id)
                      }
                      className="absolute top-2 right-2 bg-white border border-gray-300 rounded-full p-1 shadow-sm hover:bg-gray-100"
                    >
                      <svg
                        className="w-4 h-4 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div>
              <span className="text-lg font-semibold">Place images</span>
              <div className="mt-5">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-600 dark:text-neutral-300">
                      <label
                        htmlFor="place-images-upload"
                        className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Upload multiple files</span>
                        <input
                          id="place-images-upload"
                          name="place-images-upload"
                          type="file"
                          accept="image/*"
                          multiple
                          className="sr-only"
                          onChange={handlePlaceImagesChange}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>

                {placeImagesPreviews.map((preview, index) => (
                  <div key={index} className="mt-3 relative inline-block">
                    <img
                      src={typeof preview === "string" ? preview : preview.url} // Ensure compatibility for plain strings or objects
                      alt={`Place Preview ${index + 1}`}
                      className="rounded-md w-30 h-30 object-cover"
                      style={{ width: "120px", height: "120px" }}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemovePlaceImage(index, preview?.id)} // Pass preview.id if it exists
                      className="absolute top-2 right-2 bg-white border border-gray-300 rounded-full p-1 shadow-sm hover:bg-gray-100"
                    >
                      <svg
                        className="w-4 h-4 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-end space-x-5">
              <ButtonSecondary href={`/add-listing-2?id=${globalId || id}`}>
                Go back
              </ButtonSecondary>
              <ButtonPrimary type="submit" disabled={loading}>
                {loading ? "Loading..." : "Continue"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </>
    </CommonLayoutV2>
  );
};

export default Image;
