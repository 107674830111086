import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import {
  getallupdateProperties,
  getRoomAmenitiesProperty,
  getRoomFeaturesProperty,
  getBedType,
  getRoomType,
  uploadImageProperties,
  deleteImage,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { roomRuleValidationSchema } from "utils/formSchema";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import {
  BedType,
  RoomType,
} from "../../redux/reducers/Properties/PropertiesSliceTypes";
import CommonLayoutV2 from "./CommonLayoutV2";
import CustomDatePicker from "shared/DateField/CustomDatePicker";
import dayjs from "dayjs";
export interface RoomsProps {}

type RoomsAttributes = {
  rooms_attributes: RoomA[];
};

type RoomA = {
  id: string;
  name: string;
  price_per_month: string;
  status: string;
  booking_start: string;
  booking_end: string;
  description: string;
  bed_type_id: string;
  size: string;
  room_type_id: string;
  amenities: any[];
  features: any[];
};

const Rooms: FC<RoomsProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [isSharedProperty, setIsSharedProperty] = useState<boolean>(false);

  const initialRoomValues = {
    id: "",
    name: "",
    price_per_month: "",
    status: "",
    booking_start: "",
    booking_end: "",
    description: "",
    bed_type_id: "",
    size: "",
    room_type_id: "",
    amenities: [],
    features: [],
    isSharedProperty: false,
    room_images: [],
  };
  const [initialValues, setInitialValues] = useState<any>({
    rooms_attributes: [initialRoomValues],
  });

  const [amenities, setAmenities] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);
  const [bedTypes, setBedTypes] = useState<BedType[]>([]);
  const [totalRooms, setTotalRooms] = useState<number | null>(null);
  const RouteID = globalId || id;
  const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
  const [count, setCount] = useState<number>(1);
  const [roomImagePreviews, setRoomImagePreviews] = React.useState<{
    [key: number]: string[];
  }>({});

  useEffect(() => {
    if (initialValues?.rooms_attributes?.length > 0) {
      setCount(initialValues?.rooms_attributes?.length);
    }
  }, [initialValues]);

  useEffect(() => {
    if (globalId || id) {
      const fetchPropertyData = async () => {
        const response: any = await dispatch(
          getallupdateProperties({ propertyId: globalId || id })
        );

        if (response.payload?.success) {
          setIsSharedProperty(response?.payload?.property?.is_shared_property);
        }

        setTotalRooms(response?.payload?.property?.total_rooms);
        if (response.payload?.property?.rooms) {
          const mappedRooms = response.payload.property.rooms.map(
            (room: any) => ({
              id: room.id,
              name: room.name,
              price_per_month: room.price_per_month?.toString() || "",
              status: room.status,
              booking_start: room.booking_start
                ? room.booking_start.split("T")[0]
                : "",
              booking_end: room.booking_end
                ? room.booking_end.split("T")[0]
                : "",
              description: room.description || "",
              bed_type_id: room.bed_type?.id?.toString() || "",
              size: room.size?.toString() || "",
              room_type_id: room.room_type?.id?.toString() || "",
              amenities: room.amenities || [],
              features: room.features || [],
              isSharedProperty:
                response?.payload?.property?.is_shared_property || false,
              room_images: [],
              existing_room_images: room.room_images || [],
            })
          );
          const uniqueRooms = Array.from(
            new Map(mappedRooms.map((room: any) => [room?.id, room])).values()
          );
          setInitialValues({
            rooms_attributes:
              uniqueRooms.length > 0
                ? uniqueRooms
                : initialValues.rooms_attributes?.map((item: any) => ({
                    ...item,
                    isSharedProperty:
                      response?.payload?.property?.is_shared_property || false,
                  })),
          });
        }
      };
      fetchPropertyData();
    }
  }, [dispatch, globalId, id]);

  const handleAmenitiesChange = (
    amenityId: number,
    roomIndex: number,
    values: any,
    setFieldValue: any
  ) => {
    const updatedAmenities = values.rooms_attributes.map(
      (room: any, index: any) => {
        if (index === roomIndex) {
          const currentAmenities = room.amenities || [];

          const newAmenities = currentAmenities.some(
            (item: any) =>
              item.amenity_id === amenityId || item.id === amenityId
          )
            ? currentAmenities.filter(
                (item: any) =>
                  item.amenity_id !== amenityId && item.id !== amenityId
              )
            : [...currentAmenities, { amenity_id: amenityId }];

          return { ...room, amenities: newAmenities };
        }
        return room;
      }
    );

    setFieldValue("rooms_attributes", updatedAmenities);
  };

  const handleFeaturesChange = (
    featureId: number,
    roomIndex: number,
    values: any,
    setFieldValue: any
  ) => {
    const updatedFeatures = values.rooms_attributes.map(
      (room: any, index: any) => {
        if (index === roomIndex) {
          const currentFeatures = room.features || [];
          const newFeatures = currentFeatures.some(
            (item: any) =>
              item.feature_id === featureId || item.id === featureId
          )
            ? currentFeatures.filter(
                (item: any) =>
                  item.feature_id !== featureId && item.id !== featureId
              )
            : [...currentFeatures, { feature_id: featureId }];

          return { ...room, features: newFeatures };
        }
        return room;
      }
    );

    setFieldValue("rooms_attributes", updatedFeatures);
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const formData = new FormData();

    formData.append(`property[id]`, globalId || id);

    for (const [index, room] of values.rooms_attributes.entries()) {
      const modifiedAmenities = room.amenities?.map((item: any) => ({
        amenity_id: item.id || item.amenity_id,
      }));

      const modifiedFeatures = room.features?.map((item: any) => ({
        feature_id: item.id || item.feature_id,
      }));

      if (room.id) {
        formData.append(
          `property[rooms_attributes][${index}][id]`,
          room.id.toString()
        );
      }
      formData.append(`property[rooms_attributes][${index}][name]`, room.name);
      formData.append(
        `property[rooms_attributes][${index}][price_per_month]`,
        room.price_per_month ? room.price_per_month.toString() : "0"
      );
      formData.append(
        `property[rooms_attributes][${index}][status]`,
        room.status
      );
      formData.append(
        `property[rooms_attributes][${index}][booking_start]`,
        room.booking_start || ""
      );
      formData.append(
        `property[rooms_attributes][${index}][booking_end]`,
        room.booking_end || ""
      );
      formData.append(
        `property[rooms_attributes][${index}][description]`,
        room.description
      );
      formData.append(
        `property[rooms_attributes][${index}][bed_type_id]`,
        room.bed_type_id.toString()
      );
      formData.append(
        `property[rooms_attributes][${index}][size]`,
        room.size.toString()
      );
      formData.append(
        `property[rooms_attributes][${index}][room_type_id]`,
        room.room_type_id.toString()
      );

      if (modifiedAmenities) {
        modifiedAmenities.forEach((amenity: any, amenityIndex: number) => {
          formData.append(
            `property[rooms_attributes][${index}][room_amenities_attributes][${amenityIndex}][amenity_id]`,
            amenity.amenity_id
          );
        });
      }

      // Append features
      if (modifiedFeatures) {
        modifiedFeatures.forEach((feature: any, featureIndex: number) => {
          formData.append(
            `property[rooms_attributes][${index}][room_features_attributes][${featureIndex}][feature_id]`,
            feature.feature_id
          );
        });
      }
      const newRoomImages = room.room_images?.filter(
        (image: any) => image instanceof File
      );
      const existingImages = room.existing_room_images || [];

      if (
        (newRoomImages && newRoomImages.length > 0) ||
        existingImages.length > 0
      ) {
        if (newRoomImages && newRoomImages.length > 0) {
          newRoomImages.forEach((image: File) => {
            if (image instanceof File) {
              formData.append(
                `property[rooms_attributes][${index}][room_images][]`,
                image
              );
            }
          });
        }

        if (existingImages.length > 0) {
          existingImages.forEach((imageUrl: string) => {
            if (imageUrl && typeof imageUrl === "string") {
              formData.append(
                `property[rooms_attributes][${index}][room_images][]`,
                imageUrl
              );
            }
          });
        }
      } else {
        console.log("No images selected to send in payload.");
      }
    }

    try {
      const response = await dispatch(uploadImageProperties(formData)).unwrap();
      if (response) {
        successHandler(response?.message || "Updated successfully");
        navigate("/my_propeties");
      }
    } catch (error) {
      console.error("Error updating property: ", error);
    }
  };

  const handleRoomImagesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setFieldValue: any
  ) => {
    const files = e.target.files;

    if (files) {
      const newPreviews = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );

      setRoomImagePreviews((prevPreviews) => {
        const updatedPreviews = { ...prevPreviews };
        updatedPreviews[index] = newPreviews;
        return updatedPreviews;
      });
      const existingImages =
        initialValues.rooms_attributes[index]?.existing_room_images || [];
      const combinedImages = [...existingImages, ...Array.from(files)];
      setFieldValue(`rooms_attributes[${index}].room_images`, combinedImages);
    }
  };

  const handleRemoveRoomImage = async (
    previewIndex: number,
    index: number,
    values: any,
    setFieldValue: any
  ) => {
    const existingImages =
      values?.rooms_attributes[index]?.existing_room_images || [];
    const isExistingImage = previewIndex < existingImages.length;

    if (isExistingImage) {
      const imageIdToRemove = existingImages[previewIndex]?.id;

      try {
        await dispatch(deleteImage({ id: imageIdToRemove }));
        const updatedExistingImages = existingImages.filter(
          (_: any, idx: number) => idx !== previewIndex
        );
        setFieldValue(
          `rooms_attributes[${index}].existing_room_images`,
          updatedExistingImages
        );
      } catch (error) {
        console.error("Error removing image:", error);
      }
    } else {
      const newPreviews = roomImagePreviews[index] || [];
      const updatedPreviews = newPreviews.filter(
        (_: string, idx: number) => idx !== previewIndex - existingImages.length
      );

      setRoomImagePreviews((prevPreviews) => {
        const updatedPreviewsMap = { ...prevPreviews };
        updatedPreviewsMap[index] = updatedPreviews;
        return updatedPreviewsMap;
      });
      const newImages = values.rooms_attributes[index]?.room_images || [];
      const updatedNewImages = newImages.filter(
        (_: any, idx: number) => idx !== previewIndex - existingImages.length
      );
      setFieldValue(`rooms_attributes[${index}].room_images`, updatedNewImages);
    }
  };

  useEffect(() => {
    dispatch(getRoomFeaturesProperty())
      .unwrap()
      .then((response) => setFeatures(response.data))
      .catch((error) => console.error("Error fetching features:", error));

    dispatch(getRoomAmenitiesProperty())
      .unwrap()
      .then((response) => setAmenities(response.data))
      .catch((error) => console.error("Error fetching features:", error));
  }, [dispatch]);

  useEffect(() => {
    const fetchBedTypes = async () => {
      try {
        const response = await dispatch(getBedType()).unwrap();
        if (response.success) {
          setBedTypes(response.data);
        } else {
          console.error("Failed to fetch bed types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching bed types:", error);
      }
    };

    const fetchRoomTypes = async () => {
      try {
        const response = await dispatch(getRoomType()).unwrap();
        if (response.success) {
          setRoomTypes(response.data);
        } else {
          console.error("Failed to fetch room types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching room types:", error);
      }
    };
    fetchBedTypes();
    fetchRoomTypes();
  }, [dispatch]);

  return (
    <CommonLayoutV2 currentHref="/add-listing-8" PropertyID={RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Room Details</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Provide detailed information about your property to help guests make
          informed decisions.
        </span>
        <Formik
          initialValues={initialValues}
          validationSchema={roomRuleValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
          }) => {
            return (
              <>
                {values.rooms_attributes.map((room: any, index: any) => (
                  <div
                    key={index}
                    className={`${
                      index > 0 ? "mt-12 pt-8 border-t border-neutral-200" : ""
                    }`}
                  >
                    <h3 className="text-xl font-bold text-neutral-900 dark:text-neutral-100 mb-6">
                      Room {index + 1}
                    </h3>
                    <FormItem label=" Room Name" className="mt-4">
                      <Input
                        type="text"
                        placeholder="Enter Room name"
                        {...getFieldProps(`rooms_attributes[${index}].name`)}
                      />
                      {errors.rooms_attributes &&
                        Array.isArray(errors.rooms_attributes) &&
                        typeof errors.rooms_attributes[index] === "object" &&
                        errors.rooms_attributes[index] !== null &&
                        (
                          errors.rooms_attributes[index] as {
                            [key: string]: string;
                          }
                        ).name && (
                          <div className="text-red-500 text-sm mt-1">
                            {
                              (
                                errors.rooms_attributes[index] as {
                                  [key: string]: string;
                                }
                              ).name
                            }
                          </div>
                        )}
                    </FormItem>
                    {isSharedProperty && (
                      <FormItem label="Price per Month" className="mt-4">
                        <Input
                          type="number"
                          name={`rooms_attributes[${index}].price_per_month`}
                          placeholder="0.00"
                          onChange={handleChange}
                          value={
                            values.rooms_attributes[index]?.price_per_month
                          }
                        />
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).price_per_month && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).price_per_month
                              }
                            </div>
                          )}
                      </FormItem>
                    )}
                    <FormItem label="Status" className="mt-4">
                      <Select
                        name={`rooms_attributes[${index}].status`}
                        onChange={handleChange}
                        value={room.status}
                      >
                        <option value="">Select status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Select>
                      {errors.rooms_attributes &&
                        Array.isArray(errors.rooms_attributes) &&
                        typeof errors.rooms_attributes[index] === "object" &&
                        errors.rooms_attributes[index] !== null &&
                        (
                          errors.rooms_attributes[index] as {
                            [key: string]: string;
                          }
                        ).status && (
                          <div className="text-red-500 text-sm mt-1">
                            {
                              (
                                errors.rooms_attributes[index] as {
                                  [key: string]: string;
                                }
                              ).status
                            }
                          </div>
                        )}
                    </FormItem>
                    {isSharedProperty && (
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                        {/* Booking Start Date */}
                        <FormItem
                          label="Availability Start Date"
                          className="mt-4"
                        >
                          <CustomDatePicker
                            value={
                              room.booking_start
                                ? dayjs(room.booking_start)
                                : null
                            }
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: `rooms_attributes[${index}].booking_start`,
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            disabled={false}
                            error={
                              errors.rooms_attributes &&
                              Array.isArray(errors.rooms_attributes) &&
                              typeof errors.rooms_attributes[index] ===
                                "object" &&
                              errors.rooms_attributes[index] !== null &&
                              (
                                errors.rooms_attributes[index] as {
                                  [key: string]: string;
                                }
                              ).booking_start
                            }
                            placeholder="Select Start Date"
                            disabledDate={(current) =>
                              current
                                ? current.isBefore(dayjs().startOf("day"))
                                : false
                            }
                          />
                        </FormItem>
                        <FormItem
                          label="Availability End Date"
                          className="mt-4"
                        >
                          <CustomDatePicker
                            value={
                              room.booking_end ? dayjs(room.booking_end) : null
                            }
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: `rooms_attributes[${index}].booking_end`,
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            disabled={false}
                            error={
                              errors.rooms_attributes &&
                              Array.isArray(errors.rooms_attributes) &&
                              typeof errors.rooms_attributes[index] ===
                                "object" &&
                              errors.rooms_attributes[index] !== null &&
                              (
                                errors.rooms_attributes[index] as {
                                  [key: string]: string;
                                }
                              ).booking_end
                            }
                            placeholder="Select End Date"
                            disabledDate={(current) =>
                              current
                                ? current.isBefore(dayjs().startOf("day")) ||
                                  (room.booking_start &&
                                    current.isBefore(dayjs(room.booking_start)))
                                : false
                            }
                          />
                        </FormItem>
                      </div>
                    )}

                    <FormItem label="Description" className="mt-4">
                      <textarea
                        className="block w-full h-32 px-4 py-3 text-sm rounded-2xl border-neutral-200"
                        name={`rooms_attributes[${index}].description`}
                        placeholder="Enter room description"
                        onChange={handleChange}
                        value={room.description}
                      />
                      {errors.rooms_attributes &&
                        Array.isArray(errors.rooms_attributes) &&
                        typeof errors.rooms_attributes[index] === "object" &&
                        errors.rooms_attributes[index] !== null &&
                        (
                          errors.rooms_attributes[index] as {
                            [key: string]: string;
                          }
                        ).description && (
                          <div className="text-red-500 text-sm mt-1">
                            {
                              (
                                errors.rooms_attributes[index] as {
                                  [key: string]: string;
                                }
                              ).description
                            }
                          </div>
                        )}
                    </FormItem>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                      <FormItem label="Bed Type" className="mt-4">
                        <Select
                          name={`rooms_attributes[${index}].bed_type_id`}
                          onChange={handleChange}
                          value={room.bed_type_id}
                        >
                          <option value="">Select bed type</option>
                          {bedTypes.map((bedType) => (
                            <option key={bedType.id} value={bedType.id}>
                              {bedType.name}
                            </option>
                          ))}
                        </Select>
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).bed_type_id && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).bed_type_id
                              }
                            </div>
                          )}
                      </FormItem>
                      <FormItem label="Room Size (sq ft)" className="mt-4">
                        <Input
                          type="number"
                          name={`rooms_attributes[${index}].size`}
                          placeholder="Enter room size"
                          onChange={handleChange}
                          value={room.size}
                        />
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).size && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).size
                              }
                            </div>
                          )}
                      </FormItem>
                      <FormItem label="Room Type" className="mt-4">
                        <Select
                          name={`rooms_attributes[${index}].room_type_id`}
                          onChange={handleChange}
                          value={room.room_type_id}
                        >
                          <option value="">Select room type</option>
                          {roomTypes.map((roomType) => (
                            <option key={roomType.id} value={roomType.id}>
                              {roomType.name}
                            </option>
                          ))}
                        </Select>
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).room_type_id && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).room_type_id
                              }
                            </div>
                          )}
                      </FormItem>
                    </div>
                    <FormItem>
                      <h2 className="text-2xl font-semibold my-5">Amenities</h2>
                      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        {amenities.map((amenity: any) => (
                          <CheckboxV2
                            key={amenity.id.toString()}
                            id={amenity.id.toString()}
                            label={amenity.name}
                            name={`rooms_attributes[${index}].amenities`}
                            onChange={(checked) =>
                              handleAmenitiesChange(
                                amenity?.id,
                                index,
                                values,
                                setFieldValue
                              )
                            }
                            checked={values.rooms_attributes[
                              index
                            ]?.amenities?.some(
                              (item: any) =>
                                item.amenity_id === amenity.id ||
                                item.id === amenity.id
                            )}
                          />
                        ))}
                      </div>
                    </FormItem>
                    <FormItem>
                      <h2 className="text-2xl font-semibold my-5">Features</h2>
                      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        {features.map((feature: any) => (
                          <CheckboxV2
                            key={feature.id.toString()}
                            id={feature.id.toString()}
                            label={feature.name}
                            name={`rooms_attributes[${index}].features`}
                            onChange={(checked) =>
                              handleFeaturesChange(
                                feature?.id,
                                index,
                                values,
                                setFieldValue
                              )
                            }
                            checked={values.rooms_attributes[
                              index
                            ]?.features?.some(
                              (item: any) =>
                                item.feature_id === feature.id ||
                                item.id === feature.id
                            )}
                          />
                        ))}
                      </div>
                    </FormItem>
                    <div key={index}>
                      {/* Image upload section */}
                      <div className=" mt-5">
                        <span className="text-lg font-semibold">
                          Room images
                        </span>
                        <div className="mt-5">
                          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              <label
                                htmlFor={`room-images-upload-${index}`}
                                className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500"
                              >
                                <span>Upload multiple files</span>
                                <input
                                  id={`room-images-upload-${index}`}
                                  name={`room-images-upload-${index}`}
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  className="sr-only"
                                  onChange={(e) =>
                                    handleRoomImagesChange(
                                      e,
                                      index,
                                      setFieldValue
                                    )
                                  }
                                />
                              </label>
                              <p className="text-xs text-neutral-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                          <div className="mt-3">
                            {(room.existing_room_images &&
                              room.existing_room_images.length > 0) ||
                            roomImagePreviews[index] ? (
                              [
                                ...(room.existing_room_images || []).map(
                                  (image: { url: any }) => image.url
                                ),
                                ...(roomImagePreviews[index] || []),
                              ].map((preview: string, previewIndex: number) => (
                                <div
                                  key={`combined-image-${previewIndex}`}
                                  className="mt-3 relative inline-block mr-3"
                                >
                                  <img
                                    src={preview}
                                    alt={`Room ${index + 1} Image ${
                                      previewIndex + 1
                                    }`}
                                    className="rounded-md object-cover"
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveRoomImage(
                                        previewIndex,
                                        index,
                                        values,
                                        setFieldValue
                                      )
                                    }
                                    className="absolute top-2 right-2 bg-white border border-gray-300 rounded-full p-1 shadow-sm hover:bg-gray-100"
                                  >
                                    <svg
                                      className="w-4 h-4 text-red-500"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              ))
                            ) : (
                              <p>No images to display</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-3 ">
                      {index > 0 && (
                        <ButtonPrimary
                          type="button"
                          onClick={() => {
                            setCount((prev) => prev - 1);
                            setFieldValue(
                              "rooms_attributes",
                              values.rooms_attributes.filter(
                                (_: any, idx: number) => idx !== index
                              )
                            );
                          }}
                          className="my-5"
                        >
                          Remove Room
                        </ButtonPrimary>
                      )}
                      {totalRooms && totalRooms > count && (
                        <ButtonPrimary
                          type="button"
                          onClick={() => {
                            setCount((prev) => prev + 1);
                            setFieldValue("rooms_attributes", [
                              ...values.rooms_attributes,
                              initialRoomValues,
                            ]);
                          }}
                          className="my-5"
                        >
                          Add Another Room
                        </ButtonPrimary>
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex justify-end space-x-5 mt-5">
                  <ButtonSecondary href={`/add-listing-7?id=${globalId || id}`}>
                    Go back
                  </ButtonSecondary>
                  <ButtonPrimary
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Continue"}
                  </ButtonPrimary>
                </div>
              </>
            );
          }}
        </Formik>
      </>
    </CommonLayoutV2>
  );
};

export default Rooms;