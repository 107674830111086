
import { FC } from "react";
import { BookingRequestDetails } from "../../redux/reducers/Properties/PropertiesSliceTypes";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from "@paypal/paypal-js";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

interface InvoiceProps {
  bookingRequestDetails: BookingRequestDetails | null;
}

const Invoice: FC<InvoiceProps> = ({ bookingRequestDetails }) => {
  const navigate = useNavigate();

  const reserveBooking = useSelector(
    (state: RootState) => state.payment.reserveBooking
  );

  const onCreateOrder = (
    data: CreateOrderData,
    actions: CreateOrderActions
  ) => {
    if (reserveBooking?.booking?.total) {
    }
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            value: reserveBooking?.booking?.total
              ? reserveBooking?.booking?.total
              : "",
            currency_code: "USD",
          },
        },
      ],
      application_context: {
        return_url: `${window.location.origin}/success`, // Redirect back to this URL after payment
        cancel_url: `${window.location.origin}/booking`, // Optional: Redirect if payment is cancelled
      },
    });
  };

  const onApproveOrder = async (
    data: OnApproveData,
    actions: OnApproveActions,
    navigate: NavigateFunction
  ) => {
    if (actions.order) {
      const details = await actions.order.capture();
      if (details.status === "COMPLETED") {
        navigate("/success?payment_method=paypal&redirect_status=succeeded");
      }
    }
  };

  return (
    bookingRequestDetails && (
      <div className="flex p-6 bg-gray-100 dark:bg-gray-900 justify-center mt-5">
        <div className="w-2/3 p-6 border rounded shadow bg-white dark:bg-gray-800">
          <h2 className="text-xl font-medium mb-4 text-black dark:text-white">
            Create Invoice
          </h2>
          <div className="mb-6">
            <div className="flex flex-col space-y-2">
              <p className="font-normal text-sm text-black dark:text-gray-200">
                <strong>Period :</strong>{" "}
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  {bookingRequestDetails?.check_in_date} to{" "}
                  {bookingRequestDetails?.check_out_date}
                </span>
              </p>
              <p className="font-medium text-sm text-black dark:text-gray-200">
                <strong>No. of Months:</strong>{" "}
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  1
                </span>
              </p>
              <p className="font-medium text-sm text-black dark:text-gray-200">
                <strong>No. of guests:</strong>{" "}
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  {bookingRequestDetails?.number_of_guests}
                </span>
              </p>
              <p className="font-medium text-sm text-black dark:text-gray-200">
                <strong>Price per Month:</strong>{" "}
                <span className="font-normal text-gray-500 dark:text-gray-400">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(bookingRequestDetails?.item_total || 0)}
                </span>
              </p>
            </div>
          </div>
          <div>
            <table className="w-full mb-4">
              <thead>
                <tr className="border-b">
                  <th className="text-left font-medium text-black dark:text-gray-300">
                    Detail
                  </th>
                  <th className="text-left font-medium text-black dark:text-gray-300">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                  <td className="pt-4 pb-4">First month's rent</td>
                  <td className="pt-4 pb-4">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(bookingRequestDetails?.item_total || 0)}
                  </td>
                </tr>
                <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                  <td className="pt-4 pb-4">Tax total</td>
                  <td className="pt-4 pb-4">
                    $ {bookingRequestDetails?.tax_total}
                  </td>
                </tr>
                <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                  <td className="pt-4 pb-4">Invoice total</td>
                  <td className="pt-4 pb-4">
                    $ {bookingRequestDetails?.invoice?.total}0
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-between font-medium text-black dark:text-white">
              <span>Total</span>
              <span>$ {bookingRequestDetails?.total}0</span>
            </div>
          </div>
         <div className="flex flex-wrap gap-y-4 gap-x-4 mt-6">
  {/* Credit Card Button */}
  <div className="flex-1 min-w-[180px]">
    <button
      onClick={() => navigate("/checkout")}
      className="px-4 py-2 bg-blue-500 text-white rounded w-full h-12 text-sm md:text-base truncate dark:bg-blue-600 dark:hover:bg-blue-700"
    >
      Pay with Credit Card
    </button>
  </div>

  {/* PayPal Button */}
  {process.env.REACT_APP_PAYPAL_CLIENT_ID && (
    <div className="flex-1 min-w-[180px]">
      <PayPalScriptProvider
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: "USD",
          intent: "capture",
        }}
      >
        <PayPalButtons
          style={{
            layout: "horizontal",
            color: "blue",
            shape: "rect",
            height: 48,
            tagline: false,
          }}
          className="w-full h-full"
          createOrder={(data, actions) => onCreateOrder(data, actions)}
          onApprove={(data, actions) => onApproveOrder(data, actions, navigate)}
        />
      </PayPalScriptProvider>
    </div>
  )}
</div>

        </div>
      </div>
    )
  );
};

export default Invoice;
