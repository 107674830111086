
import React, { useState } from "react";
import { User } from "../../../redux/reducers/UserSlice/UserSliceType";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { deleteMessage } from "../../../redux/reducers/UserSlice/UserSlice";

interface MessageItemType {
  message: any;
  currentUser: User | null;
  selectChat: any;
  getName: (name: string) => string;
  onDeleteMessage?: (messageId: string) => void; // Optional callback for delete
}

const MessageItem = ({
  message,
  currentUser,
  selectChat,
  getName,
  onDeleteMessage,
}: MessageItemType) => {
  const [menuVisible, setMenuVisible] = useState<{ [key: string]: boolean }>(
    {}
  );
  const dispatch = useDispatch<AppDispatch>();

  const myId = currentUser?.id;
  const isHost = currentUser?.is_host;
  const isStudent = currentUser?.is_student;

  const toggleMenu = (messageId: string) => {
    setMenuVisible((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  const handleDelete = async (messageId: string) => {
    try {
      const numericMessageId = Number(messageId);
  
      await dispatch(
        deleteMessage({ chatId: selectChat?.id, messageId: numericMessageId })
      );
  
      toggleMenu(messageId);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  return (
    <div className="p-4 space-y-4">
      <h1 className="text-center text-black text-sm font-bold dark:text-white">
        {message.date}
      </h1>

      {/* Messages */}
      {message.messages?.map((item: any) => {
        const isCurrentUser = myId === item.sender_id;
        const eventFor =
          (isHost && item.event_for === "host") ||
          (isStudent && item.event_for === "student");

        const messageTime = moment
          .utc(item?.created_at)
          .local()
          .format("hh:mm A");

        const isAttachements = item?.attachments?.length > 0;
        const imageUri = item?.attachments[0];

        return (
          <div
            key={item.id}
            className={`flex ${
              eventFor
                ? "justify-center"
                : isCurrentUser
                ? "justify-end"
                : "justify-start"
            } relative`}
          >
            {/* Profile Image for non-current user */}
            <div className="h-100 w-100 space-y-1">
              {!eventFor && !isCurrentUser && (
                <img
                  src={
                    selectChat?.show?.image ||
                    "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                  }
                  alt="Profile"
                  className="w-8 h-8 rounded-full mt-6 mr-1"
                />
              )}
            </div>
            <div className="flex items-start space-y-1 space-x-2 max-w-[65%] min-w-[5%]">
              <div className="flex flex-col space-y-1 relative">
                {/* Time and Name */}
                {!eventFor && !isCurrentUser && (
                  <span className="text-xs self-start text-gray-500 dark:text-white flex items-center space-x-2">
                    <span>{getName(selectChat?.show?.name)}</span>
                    <span>{messageTime}</span>
                  </span>
                )}

                {!eventFor && isCurrentUser && (
                  <div className="flex items-center justify-end space-x-2">
                    <span className="text-xs text-gray-500 dark:text-white self-end">
                      {messageTime}
                    </span>
                    {/* Delete Button */}
                    <button
                      onClick={() => toggleMenu(item.id)}
                      className="text-gray-500 hover:text-gray-700 dark:text-white text-2xl ml-2"
                    >
                      &#x2026;
                    </button>
                    {menuVisible[item.id] && (
                      <div className="absolute top-10 left-0 bg-white dark:bg-gray-800 shadow-lg rounded-md p-2 z-50">
                        <button
                          onClick={() => handleDelete(item.id)}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {/* Message Box */}
                <div
                  className={`p-3 rounded-lg ${
                    eventFor
                      ? "text-center text-black dark:text-white"
                      : isCurrentUser
                      ? "bg-gray-600 text-left text-white"
                      : "bg-gray-200 text-left text-black"
                  }`}
                >
                  {!eventFor && isAttachements && (
                    <div>
                      <img
                        alt="attachment"
                        width={300}
                        height={200}
                        className="rounded-md object-cover"
                        src={imageUri || ""}
                      />
                    </div>
                  )}
                  <span
                    style={{ fontFamily: "Roboto, sans-serif" }}
                    className="text-sm "
                  >
                    {item.body}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageItem;
