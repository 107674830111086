import React from "react";
import moment from "moment";
import { Chat } from "../../redux/reducers/ChatReducer/ChatSliceTypes";
import { User } from "../../redux/reducers/UserSlice/UserSliceType";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { resetCount } from "../../redux/reducers/ChatReducer/ChatSlice";

interface ChatListProps {
  currentUser: User | null;
  chats: any;
  selectChat: any
  setSelectedChat: React.Dispatch<React.SetStateAction<Chat | null>>;
  getAllMessages: (chatId: number) => void;
}

const ChatList: React.FC<ChatListProps> = ({
  chats,
  selectChat,
  setSelectedChat,
  getAllMessages,
  currentUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const finalChats = chats?.map((chat: any) =>
    chat.senderId === currentUser?.id
      ? { ...chat, show: chat.receiver }
      : { ...chat, show: chat.sender }
  );
  const resetParticularCount = (id: number) => {
    // dispatch(resetCount(id))
  }

  const getName = (name: string) => {
    const modifiedName = name[0]?.toUpperCase() + name?.slice(1);
    return modifiedName;
  };

  const getLastMessage = (lastMessage: string) => {
    return lastMessage ? lastMessage : null;
  };

  const getMessageTime = (time: string) => {
    return time ? time : null;
  };

  const chatHandler = (chat: any) => {
    setSelectedChat(chat);
    getAllMessages(chat.id);
  };

  
  const defaultProfileImage = "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
  return (
    <div className="mt-6 max-h-[calc(93vh-80px)] overflow-y-auto overflow-x-hidden">
      {finalChats.map((chat: any, index: any) => (
        <button
          key={index}
          className={`w-full flex items-center py-4 px-2 ${selectChat?.id === chat?.id ? 'bg-gray-200 dark:bg-gray-600' : 'bg-neutral'}  hover:bg-neutral-200 dark:hover:bg-neutral-700 cursor-pointer rounded-lg dark:border-neutral-700 focus:outline-none`}
          onClick={() => {
            chatHandler(chat)
            resetParticularCount(chat?.id)
          }}
        >
           <div className="relative flex-shrink-0 mr-4">
            {/* Property Image */}
            {chat.property?.placeImages && chat.property.placeImages.length > 0 && (
              <img
                src={chat.property.placeImages[0] || "https://www.w3schools.com/w3images/lights.jpg"}
                alt={chat.property.title}
                className="w-16 h-16 rounded-lg object-cover"
                style={{ maxWidth: "54px", maxHeight: "54px" }}
                onError={(e) => {
                  console.error("Image failed to load:", e.currentTarget.src);
                  e.currentTarget.src = "https://www.w3schools.com/w3images/lights.jpg";
                }}
              />
            )}

            {/* Profile Image (half overlapping) */}
            <img
              src={
                chat.show.image ||
                "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
              }
              alt={chat.show.name}
              className="w-9 h-9 rounded-full absolute bottom-0 right-0 border-2 border-white"
              style={{
                transform: "translate(25%, 25%)", 
                zIndex: 1, 
              }}
              onError={(e) => {
                e.currentTarget.src = defaultProfileImage; 
              }}
            />
            <img
              src={
                chat.sender?.image ||
                "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
              }
              alt={chat.sender?.name}
              className="w-8 h-8 rounded-full absolute bottom-[-10px] right-0 border-2 border-white"
              style={{
                transform: "translate(-25%, 25%)", 
                zIndex: 1, 
              }}
            />
          </div>
          <div className="ml-4 flex-1 min-w-0" style={{ width: "100%" }}>
            <div className="flex items-center justify-between">
              <h3 style={{ fontFamily: 'Roboto, sans-serif' }} className="text-base text-neutral-500 dark:text-neutral-100 truncate">
                {getName(chat.show.name)}
              </h3>
              <p className="text-xs text-neutral-500 dark:text-neutral-400 ml-2 whitespace-nowrap">
                {getMessageTime(chat.lastMessageTime)}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="w-3/4 text-left text-sm text-neutral-500 dark:text-neutral-400 truncate">
                {getLastMessage(chat.lastMessage)}
              </p>
              {chat.unreadCount > 0 && (
                <span className="text-xs text-white rounded-full h-5 w-5 truncate bg-red-500 flex items-center justify-center">
                  {chat.unreadCount}
                </span>
              )}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default ChatList;
