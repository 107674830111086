import React, { FC, useState } from "react";
import { Popover } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";

export interface GuestsInputProps {
  className?: string;
  room: any;
  selectedRoom: number | null;
  setSelectedRoom: React.Dispatch<React.SetStateAction<number | null>>;
  numberOfGuests: number;
  guests: string;
  setGuests: React.Dispatch<React.SetStateAction<string>>;
}

const SelectGuests: FC<GuestsInputProps> = ({
  className = "flex-1",
  room,
  selectedRoom,
  setSelectedRoom,
  numberOfGuests,
  guests,
  setGuests,
}) => {
  const options = Array.from({ length: numberOfGuests }, (_, i) =>
    (i + 1).toString()
  );

  const onChange = (value: Option, roomId: number) => {
    setSelectedRoom(roomId)
    setGuests(value.value);
  };

  return (
    <>
      <Popover className={`flex relative ${className}`}>
        {({ open }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
                open ? "shadow-lg" : ""
              }`}
            >
              <Popover.Button
                className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                </div>
                <div className="flex-grow">
                  <p>{selectedRoom === room.id ? guests : "1"}</p>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    {guests ? "Guests" : "Add guests"}
                  </span>
                </div>
              </Popover.Button>
            </div>
          </>
        )}
      </Popover>
      <Dropdown
        options={options}
        onChange={(value) => onChange(value, room.id)}
        value={selectedRoom === room.id ? guests : "1"}
        placeholder="Select an option"
      />
    </>
  );
};

export default SelectGuests;
