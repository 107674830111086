import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

const DatePickerCustomHeaderTwoMonth = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="flex items-center justify-between px-4 py-2">
      {/* Previous Month Button */}
      <button
        aria-label="Previous Month"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className={`flex items-center justify-center p-2 rounded-full ${
          prevMonthButtonDisabled
            ? "cursor-not-allowed opacity-50"
            : "hover:bg-gray-100 dark:hover:bg-gray-700"
        }`}
      >
        <ChevronLeftIcon className="w-5 h-5" />
      </button>

      {/* Current Month */}
      <span className="text-lg font-medium">
        {monthDate.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>

      {/* Next Month Button */}
      <button
        aria-label="Next Month"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
        className={`flex items-center justify-center p-2 rounded-full ${
          nextMonthButtonDisabled
            ? "cursor-not-allowed opacity-50"
            : "hover:bg-gray-100 dark:hover:bg-gray-700"
        }`}
      >
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default DatePickerCustomHeaderTwoMonth;
