import React, { FC, useEffect, useState } from "react";
import GallerySliderV2 from "components/GallerySlider/GallerySliderV2";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useLocation } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getallupdateProperties } from "../../redux/reducers/Properties/PropertiesSlice";

export interface StayCardV2Props {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
}

const StayCardV2: FC<StayCardV2Props> = ({
  size = "default",
  className = "",
  data,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const propertyId = globalId || id;

  const [mappedPropertyData, setMappedPropertyData] = useState<any | null>(
    null
  );

  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId || id) {
      dispatch(getallupdateProperties({ propertyId })).then((response) => {
        const property = response?.payload?.property;
        setMappedPropertyData({
          title: property?.title,
          address: property?.address,
          price: property?.price_per_month,
          galleryImgs: property?.place_images,
          reviewStart: 4, // Set a fixed value or update it based on your data
          reviewCount: 100, // Adjust this as needed
          listingCategory: { name: property?.property_type?.name || "Unknown" },
          bedrooms: property?.total_rooms,
        });
      });
    }
  }, [dispatch, propertyId, id]);

  const renderSliderGallery = () => (
    <div className="relative w-full">
      <GallerySliderV2
        uniqueID={`StayCard_${id}`}
        ratioClass="aspect-w-4 aspect-h-3"
        galleryImgs={mappedPropertyData?.galleryImgs || []}
      />
      <BtnLikeIcon isLiked={false} className="absolute right-3 top-3 z-[1]" />
      {/* Use SaleOffBadge conditionally if you have the logic for it */}
      {false && <SaleOffBadge className="absolute left-3 top-3" />}
    </div>
  );

  const renderContent = () => (
    <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
      <div className="space-y-2">
        <span className="text-sm text-neutral-500 dark:text-neutral-400">
          {mappedPropertyData?.listingCategory.name} ·{" "}
          {mappedPropertyData?.bedrooms} beds
        </span>
        <h2
          className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
            }`}
        >
          <span className="line-clamp-1">{mappedPropertyData?.title}</span>
        </h2>
        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {size === "default" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
          <span>{mappedPropertyData?.address}</span>
        </div>
      </div>
      <div className="w-14 border-b border-neutral-100 dark:border-neutral-800" />
      <div className="flex justify-between items-center">
        <span className="text-base font-semibold">
          {mappedPropertyData?.price}
          {` `}
          {size === "default" && (
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /month
            </span>
          )}
        </span>
        {!!mappedPropertyData?.reviewStart && (
          <StartRating
            reviewCount={mappedPropertyData?.reviewCount}
            point={mappedPropertyData?.reviewStart}
          />
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={data?.href || "#"}>{renderContent()}</Link>
    </div>
  );
};

export default StayCardV2;
