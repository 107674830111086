import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";
import {
  getallPropertieslisting,
} from "../../redux/reducers/Properties/PropertiesSlice";
import StayCardV5 from "components/StayCard/StayCardV5";

// OTHER DEMO WILL PASS PROPS
const DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  setAllData: React.Dispatch<React.SetStateAction<StayDataType[]>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  fetchOldData: () => Promise<void>
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}
const SectionGridFeaturePlacesV4: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DATA,
  gridClass = "",
  heading = "My Properties",
  subHeading = "",
  headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"],
  setAllData,
  query,
  setQuery,
  setPage,
  fetchOldData,
  page,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);
  const [filteredData, setFilteredData] = useState<StayDataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);


  const getRandomIndex = () => Math.floor(Math.random() * 8);


  const fetchData = async (pge: number) => {
    const data = {
      page: pge,
      query: query,
    };
    setIsLoading(true);
    try {
      const res = await dispatch(getallPropertieslisting(data)).unwrap();
      const newData = res?.properties?.map((apiItem: any) => {
        const randomIndex = getRandomIndex();
        const fallback = DEMO_DATA[randomIndex];
        return {
          ...fallback,
          id: apiItem?.id,
          title: apiItem?.title || fallback?.title,
          place_images: apiItem?.place_images,
          address: apiItem?.address,
          availability_start: apiItem.availability_start || fallback?.date,
          availability_end: apiItem.availability_end || fallback?.date,
          price: apiItem?.price_per_month,
          href: `/listing-stay-detail?id=${apiItem?.id}`,
          property_state: apiItem?.property_state || null,
          active: apiItem?.active !== undefined ? apiItem.active : false,
          property_category:apiItem?.property_category.name,
          property_type: apiItem?.property_type?.name,
          city: apiItem?.city,
          country: apiItem?.country
        };
      });
      setAllData(newData)
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleLoadMore = async () => {
    setPage((prev) => prev + 1);
    fetchData(page + 1)
  };

  const handleGoBack = async () => {
    setPage((prev) => prev - 1);
    fetchData(page - 1)
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    setFilteredData(stayListings);
  }, [stayListings]);

  const renderCard = (stay: StayDataType) => {
    return (
      <StayCardV5
        key={stay.id}
        data={stay}
        setAllData={setAllData}
        stayListings={filteredData}
      />
    );
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div className="mb-8 flex items-center">
        <input
          type="text"
          value={query}
          onChange={handleSearchChange}
          placeholder="Search for a property..."
          className="p-3 w-full md:w-1/3 border rounded-lg"
        />
        <button
          onClick={fetchOldData}
          className="ml-4 p-3 bg-primary-500 text-white rounded-lg"
        >
          Search
        </button>
      </div>
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {filteredData.length > 0 ? (
          filteredData.map((stay) => renderCard(stay))
        ) : (
          <div>No properties found matching your search</div>
        )}
      </div>

      <div className="flex mt-16 justify-center items-center space-x-4">
        <ButtonPrimary
          loading={loadingPrev}
          onClick={handleGoBack}
          disabled={loadingNext}
        >
          Go Back
        </ButtonPrimary>
        <ButtonPrimary
          loading={loadingNext}
          onClick={handleLoadMore}
          disabled={loadingPrev}
        >
          Show me more
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlacesV4;
