/**
 * Converts a string in the format "YYYY-MM-DD" into a Date object.
 * @param dateString - The date string in "YYYY-MM-DD" format.
 * @returns {Date} - The corresponding Date object.
 */
export function convertToDate(dateString: string): Date {
  if (!dateString) {
    // Return a default date (e.g., today) if the input is invalid or empty
    console.warn('Invalid date string provided:', dateString);
    return new Date(); // Default to today's date
  }

  const [year, month, day] = dateString.split("-").map(Number);

  // Validate if the parsed date is correct (year, month, and day should be valid numbers)
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    console.warn('Invalid date parts extracted from string:', dateString);
    return new Date(); // Default to today's date if the date string is not valid
  }

  // Subtract 1 from month because JavaScript Date months are 0-indexed
  return new Date(year, month - 1, day);
}


//   Normalize the date to the local time zone when selecting from the calendar.
// Alternatively, store the date in UTC and display it correctly by converting it to local time during rendering.

export const normalizeToLocalDate = (date: Date | null): Date | null => {
  if (!date) return null;
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  ); // Local midnight
};
