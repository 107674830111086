import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getBookingsQueryDetails,
  postChatBooking,
  updateBookingsQueryDetails,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";
import successHandler from "utils/helpers/SuccessHandler";
import { useSelector } from "react-redux";
import ErrorHandler from "utils/helpers/ErrorHandler";
import DateSelectionModal from "shared/DateField/DateSelectionModal";
import { useNavigate } from "react-router-dom";

export interface BookingQueryDetails {
  className?: string;
  // data?: StayDataType;
  // size?: "default" | "small";
}

const BookingQueryDetails: FC<BookingQueryDetails> = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [bookingRequestDetails, setBookingRequestDetails] = useState<
    any | null
  >(null);
  const [replyText, setReplyText] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modifiedBookingMap = bookingRequestDetails?.messages
    ? [...bookingRequestDetails?.messages]
    : [];
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { globalId } = useSelector((state: RootState) => state.property);
  const bookingId = globalId || id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const checkInDate = bookingRequestDetails?.booking_query?.check_in_date
    ? new Date(
        bookingRequestDetails?.booking_query?.check_in_date
      ).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "";

  const checkOutDate = bookingRequestDetails?.booking_query?.check_out_date
    ? new Date(
        bookingRequestDetails?.booking_query?.check_out_date
      ).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "";

  const handleFileChange = (e: { target: { files: FileList | null } }) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file && file.type.startsWith("image/")) {
        setSelectedFile(file);
      }
    }
  };

  const fetchAllManageBooking = async () => {
    const action = await dispatch(getBookingsQueryDetails(id));
    if (action?.payload?.success) {
      setBookingRequestDetails(action?.payload);
    }
  };

  const handleSubmit = async () => {
    if (!replyText.trim() && !selectedFile) return;
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("message[body]", replyText);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      const data = {
        chatId: bookingRequestDetails?.booking_query?.chat?.id,
        formData,
      };
      await dispatch(postChatBooking(data)).unwrap();
      setReplyText("");
      setSelectedFile(null);
      fetchAllManageBooking();
    } catch (error) {
      console.error("Failed to send reply:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateBooking = async () => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.id;
    const state = "booking_invitation";

    try {
      const action = await dispatch(
        updateBookingsQueryDetails({ id, propertyId, state })
      );

      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking Invitation  Send successfully!");
      } else {
        ErrorHandler("Failed to update booking state.");
      }
    } catch (error) {
      ErrorHandler("Error updating booking state.");
    }
  };

  const handleDeclineBooking = async () => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.id;
    const state = "rejected";

    try {
      const action = await dispatch(
        updateBookingsQueryDetails({ id, propertyId, state })
      );

      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking Decline successfully!");
      } else {
        ErrorHandler("Failed to Decline booking state.");
      }
    } catch (error) {
      ErrorHandler("Error updating booking state.");
    }
  };

  const handleBookingRequest = async () => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.id;
    const state = "accepted";
    try {
      const action = await dispatch(
        updateBookingsQueryDetails({ id, propertyId, state })
      );
      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking Request Send successfully!");
        navigate(`/booking_request?id=${action.payload.booking.id}`);
      } else {
        ErrorHandler("Failed to Send booking .");
      }
    } catch (error) {
      ErrorHandler("Failed to Send booking.");
    }
  };
  const handleDateSubmit = async (dates: any) => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.id;
    const state = "request_change";
    const check_in_date = dates?.startDate;
    const check_out_date = dates?.endDate;

    try {
      const action = await dispatch(
        updateBookingsQueryDetails({
          id,
          propertyId,
          state,
          check_in_date,
          check_out_date,
        })
      );

      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking date changed successfully!");

        // Re-fetch booking details to update the state
        await dispatch(getBookingsQueryDetails(id));
      } else {
        ErrorHandler("Failed to change the date.");
      }
    } catch (error) {
      ErrorHandler("Failed to send booking request.");
    }
  };

  useEffect(() => {
    fetchAllManageBooking();
  }, [dispatch]);


  useEffect(() => {
    if (
      bookingRequestDetails?.booking_query?.state === "accepted" &&
      currentUser?.is_host 
    ) {
      navigate(
        `/booking_request?id=${bookingRequestDetails?.booking?.id}`
      );
    }
  }, [bookingRequestDetails, navigate]);

  const renderSliderGallery = () => (
    <div className="w-full">
      <GallerySlider
        uniqueID={`StayCard_${id}`}
        ratioClass="aspect-w-4 aspect-h-3"
        galleryImgs={
          bookingRequestDetails?.booking_query?.property?.place_images || []
        }
        href={"#"}
      />
    </div>
  );

  const pathArray = [
    { path: 1, type: "Receive a message", state: "send_message" },
    { path: 2, type: "Send invitation to book", state: "booking_invitation" },
    { path: 3, type: "Wait for confirmation", state: "invitation_sent" },
    {
      path: 4,
      type: "Wait for payment of first month",
      state: "invoice_sent",
    },
    { path: 5, type: "Booking confirmed", state: "confirmed" },
  ];

  return (
    <div className="max-w-7xl mx-auto p-4 lg:container ">
      <h1 className="text-xl font-bold text-gray-700 dark:text-gray-200 mb-6">
        MESSAGE FROM{" "}
        {currentUser?.is_host
          ? bookingRequestDetails?.booking_query?.chat?.sender?.name ||
            "Yopmail"
          : bookingRequestDetails?.booking_query?.chat?.receiver?.name ||
            "Host"}
      </h1>

      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 mb-8">
          <div className="lg:col-span-2">
            <div className="relative flex flex-col md:flex-row justify-between items-center border border-gray-300 dark:bg-black p-8 text-xl dark:border-gray-700">
              <div className="relative flex flex-col md:flex-row justify-between items-center w-full">
                {pathArray.map((step, index) => {
                  const activeStepIndex = pathArray.findIndex(
                    (s) =>
                      s.state === bookingRequestDetails?.booking_query?.state
                  );

                  return (
                    <div
                      key={step.path}
                      className="flex flex-col items-center  w-full mb-4 md:mb-0 relative z-10"
                    >
                      <div
                        className={`w-8 h-8 rounded-full flex items-center justify-center mb-2 ${
                          index <= activeStepIndex
                            ? "bg-orange-500 text-white"
                            : "bg-gray-300 dark:bg-gray-700 text-gray-500"
                        }`}
                      >
                        {step.path}
                      </div>
                      <span className="text-xs text-center dark:text-gray-300 w-[105px]">
                        {step.path === 1 ? (
                          currentUser?.is_host ? (
                            <>
                              Send
                              <br />a message
                            </>
                          ) : (
                            "Receive a message"
                          )
                        ) : (
                          step.type
                        )}
                      </span>
                      {index < pathArray.length && (
                        <div
                          className={`absolute top-4 w-full h-1 hidden md:block -z-10 ${
                            index < activeStepIndex
                              ? "bg-orange-500"
                              : index === activeStepIndex
                              ? "bg-orange-500"
                              : "bg-gray-300 dark:bg-gray-700"
                          }`}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-gray-200 dark:bg-gray-700 p-4 rounded-lg mt-5">
              <div className="flex flex-col gap-2">
                {bookingRequestDetails?.booking_query?.state === "rejected" ? (
                  <p className="text-sm text-red-600 dark:text-red-400 font-bold flex items-center justify-center h-full">
                    Request Declined
                  </p>
                ) : currentUser?.is_host ? (
                  <>
                    <p className="te xt-sm text-gray-600 dark:text-gray-300 mb-2">
                      Invite{" "}
                      <span className="font-bold text-gray-800 dark:text-gray-100">
                        {bookingRequestDetails?.booking_query?.chat?.sender
                          ?.name || "Yopmail"}
                      </span>{" "}
                      to book your place.
                      <span className="block mt-1">
                        They will be able to book your place immediately within
                        the next 48 hours after you invite them to book.
                      </span>
                    </p>
                    <div className="flex gap-2">
                      {bookingRequestDetails?.booking_query?.state ===
                      "booking_invitation" ? (
                        <ButtonPrimary
                          className="bg-gray-500 text-white px-4 py-2 rounded-lg cursor-default"
                          disabled={true}
                        >
                          Invited
                        </ButtonPrimary>
                      ) : (
                        <ButtonPrimary
                          className="bg-green-500 text-white px-4 py-2 rounded-lg"
                          onClick={handleUpdateBooking}
                        >
                          Invite to book
                        </ButtonPrimary>
                      )}
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleDeclineBooking}
                      >
                        Decline
                      </ButtonPrimary>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                      Move forward with booking this place. Request a booking
                      from
                      <span className="font-bold text-gray-800 dark:text-gray-100">
                        {" "}
                        {bookingRequestDetails?.booking_query?.chat?.receiver
                          ?.name || "Temp"}{" "}
                      </span>
                      in order to secure this place. You will not be charged
                      until your request has been accepted by
                      <span className="font-bold">
                        {" "}
                        {bookingRequestDetails?.booking_query?.chat?.receiver
                          ?.name || "Temp"}
                        .
                      </span>
                    </p>
                    <div className="flex gap-2">
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleBookingRequest}
                      >
                        Booking Request
                      </ButtonPrimary>
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        disabled={bookingRequestDetails?.status === "canceled"}
                        onClick={() => setIsModalOpen(true)}
                      >
                        Change Requested Dates
                      </ButtonPrimary>

                      <DateSelectionModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSubmit={handleDateSubmit}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="mt-6 p-4 bg-gray-100 dark:bg-black">
                <span className="text-gray-500 dark:text-gray-400">Reply</span>
                <textarea
                  className="w-full border border-gray-300 dark:border-gray-700 rounded-lg p-3 min-h-[80px] focus:outline-none focus:border-gray-400 dark:focus:border-gray-600 mt-4 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300"
                  placeholder="Write your reply..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  disabled={isSubmitting}
                />
                <div className="flex justify-end items-center mt-3 gap-5">
                  <label
                    htmlFor="cover-image-upload"
                    className="text-blue-500 dark:text-blue-400 text-sm cursor-pointer flex items-center gap-1"
                  >
                    📎 <span>Upload file</span>
                    <input
                      id="cover-image-upload"
                      name="cover-image-upload"
                      type="file"
                      accept="image/*"
                      className="sr-only"
                      onChange={handleFileChange}
                      disabled={isSubmitting}
                    />
                  </label>
                  {selectedFile && (
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {selectedFile?.name}
                    </span>
                  )}
                  <button
                    onClick={handleSubmit}
                    disabled={
                      isSubmitting || (!replyText.trim() && !selectedFile)
                    }
                    className={`bg-orange-500 text-white px-4 py-2 rounded-lg ${
                      isSubmitting || (!replyText.trim() && !selectedFile)
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-orange-600"
                    }`}
                  >
                    {isSubmitting ? "Sending..." : "Send Reply"}
                  </button>
                </div>
              </div>
              <div className="mt-6 border border-gray-300 p-4 space-y-4 bg-white dark:bg-black dark:border-gray-700">
                {modifiedBookingMap?.map((msg: any, index: number) => (
                  <div key={index}>
                    {msg.sender?.id ? (
                      <div className="flex items-start space-x-4 p-4 bg-gray-100 dark:bg-black rounded-lg">
                        <div className="w-12 h-12 rounded-full bg-purple-200 flex items-center justify-center">
                          {msg.sender.image ? (
                            <img
                              src={msg.sender.image}
                              alt="Sender"
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            <span className="text-xl font-semibold text-white">
                              {msg.sender.name
                                ? msg.sender.name.charAt(0).toUpperCase()
                                : "?"}
                            </span>
                          )}
                        </div>

                        <div>
                          <div className="flex items-center space-x-2">
                            <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                              {msg.sender.name || "User"}
                            </h3>
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400 font-bold">
                            {msg?.body}
                          </span>
                          {/* <p className="text-sm text-gray-600 dark:text-gray-300">
                            Selected dates: {checkInDate} to {checkOutDate}
                          </p> */}
                        </div>
                      </div>
                    ) : (
                      <div className="border-b border-gray-300 dark:border-gray-700 pb-4 text-sm text-gray-600 dark:text-gray-300">
                        <span className="font-semibold text-gray-700 dark:text-gray-200">
                          System Message:
                        </span>
                        <p className="leading-8">{msg?.body}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-orange-500 h-96 rounded-lg p-6">
            <div className="flex flex-col items-center">
              <h3 className="font-semibold mb-4 text-white">
                {bookingRequestDetails?.booking_query?.chat?.sender?.image ? (
                  <img
                    src={
                      bookingRequestDetails?.booking_query?.chat?.sender?.image
                    }
                    alt="User"
                    className="w-20 h-20 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center text-gray-800 text-xl font-bold">
                    {bookingRequestDetails?.booking_query?.user?.first_name
                      ?.charAt(0)
                      .toUpperCase()}
                  </div>
                )}
              </h3>
              <h3 className="font-semibold mb-4 text-white">
                {bookingRequestDetails?.booking_query?.user?.first_name}
              </h3>
              <div className="space-y-2 w-full">
                <p className="text-sm mt-4">
                  {bookingRequestDetails?.booking_query?.user?.live_in
                    ? `Nationality: ${bookingRequestDetails?.booking_query?.user?.live_in}`
                    : "Not available"}
                </p>
                <p className="text-sm mt-4">
                  {bookingRequestDetails?.booking_query?.user?.speak_language
                    ? `Language: ${bookingRequestDetails?.booking_query?.user?.speak_language}`
                    : "Not available"}
                </p>
                {/* <p className="text-sm">👤 age</p> */}
                <p className="text-sm">
                  {bookingRequestDetails?.booking_query?.user?.email_verified
                    ? "✓ Email verified"
                    : "✘ Email not verified"}
                </p>
                {/* <div className="overflow-y-auto max-h-32">
                                    <p className="text-sm mt-4">
                                        {bookingRequestDetails?.user?.about_me
                                            ? `About me: ${bookingRequestDetails?.user?.about_me}`
                                            : "No about me information available"}
                                    </p>
                   </div> */}
              </div>
            </div>
          </div>
          <div className="w-full mt-5">
            <div className="dark:bg-black border border-gray-200 p-6 shadow-sm rounded-md w-full flex flex-col items-center justify-center dark:border-gray-700">
              <div className="space-y-4 w-full">
                <div className="space-y-1">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                    {bookingRequestDetails?.booking_query?.property?.title}
                  </h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    {bookingRequestDetails?.booking_query?.property?.address}
                  </p>
                </div>

                <div
                  className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow w-full"
                  data-nc-id=""
                >
                  <div className="w-full h-full">
                    {renderSliderGallery()}
                    {/* <Link to={data?.href || "#"}>{renderContent()}</Link>   */}
                  </div>
                </div>

                <div className="space-y-3 w-full">
                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      Period: {checkInDate} to {checkOutDate}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      No. of Months:
                    </span>
                    <span className="text-gray-800 dark:text-gray-100">1</span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      Guests:
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingQueryDetails;