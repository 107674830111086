import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosError } from "axios"; // Import AxiosError type

// Custom Error Handler
export default function ErrorHandler(error: any) {
  console.log("Error received by ErrorHandler:", error);

  // Check if the error is an AxiosError
  if (error instanceof AxiosError) {
    const { response } = error;

    if (response) {
      toast.error(response.data.error, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      toast.error("Internal server error", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }

  // Check if the error response contains error_description
  //   if (response?.data?.error_description && Array.isArray(response.data.error_description)) {
  //     // Display the first error message from error_description array
  //     toast.error(response.data.error_description[0] || 'An error occurred', {
  //       position: 'top-right',
  //       autoClose: 5000,
  //     });
  //   } else if (response?.data?.message) {
  //     // Show the message from response if present
  //     toast.error(response.data.message, {
  //       position: 'top-right',
  //       autoClose: 5000,
  //     });
  //   } else if (response?.status === 400) {
  //     // Specific handling for 400 errors without error_description
  //     toast.error('Bad Request', {
  //       position: 'top-right',
  //       autoClose: 5000,
  //     });
  //   } else {
  //     // General error message for other cases
  //     toast.error('Internal Server Error', {
  //       position: 'top-right',
  //       autoClose: 5000,
  //     });
  //   }
  // } else if (typeof error === 'object' && error.message) {
  //   // Handle custom error objects (like those passed with rejectWithValue)
  //   toast.error(error.message, {
  //     position: 'top-right',
  //     autoClose: 5000,
  //   });
  //  }
  //else {
  //   // Handle unknown errors (non-Axios errors)
  //   toast.error('An unknown error occurred.', {
  //     position: 'top-right',
  //     autoClose: 5000,
  //   });
  // }
}
