import React, { FC, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "./FormItem";
import CommonLayout from "./CommonLayout";
import Input from "shared/Input/Input";
import { ListingDetailsvalidationSchema } from "utils/formSchema";
import CommonLayoutV2 from "./CommonLayoutV2";
import __countryListing from "../../data/jsons/__countryListing.json";
export interface ListingDetailsProps {}

interface InitialValues {
  property_size: string;
  total_rooms: number;
  total_bedrooms: number;
  total_bathrooms: number;
  additionalRules: AdditionalRules[];
  roomies_attributes: any;
}

interface AdditionalRules {
  id: number | null;
  name: string;
}

interface DeletedRules {
  id: number;
  _destroy: boolean;
}

const ListingDetails: FC<ListingDetailsProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { globalId } = useSelector((state: RootState) => state.property);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [deletedRules, setDeletedRules] = useState<any[]>([]);
  const  RouteID = globalId || id;

  const [roomies, setRoomies] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<InitialValues>({
    property_size: "",
    total_rooms: 0,
    total_bedrooms: 0,
    total_bathrooms: 0,
    additionalRules: [],
    roomies_attributes: [],
  });
  const [newRuleInput, setNewRuleInput] = useState<string | never>("");

  useEffect(() => {
    const isValid = roomies.every(
      (roomie: any) => roomie.name && roomie.gender && roomie.country
    );

    if (!isValid) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [disabled, roomies]);

  useEffect(() => {
    const propertyId = globalId || id;

    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          if (response?.success && response.property) {
            setInitialValues({
              property_size: response?.property?.property_size || "",
              total_rooms: response?.property?.total_rooms || 0,
              total_bedrooms: response?.property?.total_bedrooms || 0,
              total_bathrooms: response?.property?.total_bathrooms || 0,
              additionalRules: response?.property?.additional_rules,
              roomies_attributes: response?.property?.roomies || [],
            });
            setRoomies(response?.property?.roomies);
          }
        })
        .catch((error) => {
          console.error("Error fetching property data:", error);
        });
    }
  }, [dispatch, globalId, id]);

  const handleAddRule = () => {
    if(newRuleInput?.length === 0) return null;


    const newRule = {
      id: Date.now(),
      name: newRuleInput,
      isNew: true
    };
    setInitialValues({
      ...initialValues,
      additionalRules: [...initialValues.additionalRules, newRule],
    });

    setNewRuleInput('')
    setDeletedRules([...deletedRules, newRule]);
  };

  const handleRemoveRule = (id: number) => {
    const remainingAdditionRule = initialValues.additionalRules?.filter(
      (item: AdditionalRules) => item.id !== id
    );
    setInitialValues({
      ...initialValues,
      additionalRules: remainingAdditionRule,
    });

    const deleted = {
      id: id,
      _destroy: true,
      isNew: false
    };
    setDeletedRules([...deletedRules, deleted]);
  };

  const renderNoInclude = (rule: any, index: number) => (
    <div
      className="flex items-center justify-between py-3"
      key={`${index} ${rule.id}`}
    >
      <span className="text-neutral-600 dark:text-neutral-400 font-medium">
        {rule.name}
      </span>
      <button
        type="button"
        onClick={() => handleRemoveRule(rule.id)}
        className="text-2xl text-neutral-400 hover:text-neutral-900 dark:hover:text-neutral-100"
      >
        <i className="las la-times-circle" />
      </button>
    </div>
  );

  const handleAddRoomie = () => {
    setRoomies([
      ...roomies,
      { id: Date.now(), name: "", gender: "", country: "", isAlready: false },
    ]);
  };

  const handleInputChange = (id: number, field: any, value: any) => {
    setRoomies(
      roomies.map((roomie: any) =>
        roomie.id === id ? { ...roomie, [field]: value } : roomie
      )
    );
  };

  const handleRemoveRoomie = (id: number) => {
    setRoomies(roomies.filter((roomie: any) => roomie.id !== id));
  };

  const handleSubmit = (values: typeof initialValues) => {
    const property_house_rules_attributes = Object.entries(values).map(
      ([id, value]: any) => ({
        house_rule_id: Number(id),
        value: value,
      })
    );

    const modifiedRoomies = roomies.map((item: any) => ({
      name: item.name,
      gender: item.gender,
      country: item.country,
    }));

    const modified = deletedRules?.map((item: any) => item.isNew  ? {...item, id: null} : item)

    const payload = {
      id: globalId || id,
      property_size: values.property_size,
      total_rooms: values.total_rooms,
      total_bathrooms: values.total_bathrooms,
      additional_rules_attributes: modified,
      total_bedrooms: values.total_bedrooms,
      roomies_attributes: modifiedRoomies,
    };

    dispatch(updateProperties({ property: payload }))
      .unwrap()
      .then((response) => {
        if (response?.success) {
          successHandler(response?.message || "Updated successfully");
          navigate(`/add-listing-5?id=${response.property.id || globalId}`);
        } else {
          console.log("Operation failed: ", response);
        }
      })
      .catch((error) => {
        console.log("Error in operation: ", error);
      })
      .finally(() => {
        setRoomies([]);
      });
  };

  return (
    <CommonLayoutV2 currentHref="/add-listing-4" PropertyID= {RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Size of your location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={ListingDetailsvalidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, getFieldProps }) => (
            <Form className="space-y-8">
              {/* Property Size */}
              <div className="flex space-x-4">
                <div className="flex-1">
                  <FormItem label="Property Size (m²)">
                    <Input
                      type="text"
                      placeholder="Enter property size"
                      {...getFieldProps("property_size")} // Bind to Formik
                      className="input-field rounded-none w-full"
                    />
                    {errors.property_size && touched.property_size && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.property_size}
                      </div>
                    )}
                  </FormItem>
                </div>

                <div className="flex-1">
                  <FormItem label="Rooms">
                    <Input
                      type="text"
                      placeholder="Enter number of rooms"
                      {...getFieldProps("total_rooms")}
                      className="input-field rounded-none w-full"
                    />
                    {errors.total_rooms && touched.total_rooms && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.total_rooms}
                      </div>
                    )}
                  </FormItem>
                </div>
              </div>

              {/* Bedroom and Bathroom */}
              <div className="flex space-x-4">
                <div className="flex-1">
                  <FormItem label="Bedroom">
                    <Input
                      type="text"
                      placeholder="Enter number of bedrooms"
                      {...getFieldProps("total_bedrooms")}
                      className="input-field rounded-none w-full"
                    />
                    {errors.total_bedrooms && touched.total_bedrooms && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.total_bedrooms}
                      </div>
                    )}
                  </FormItem>
                </div>
                <div className="flex-1">
                  <FormItem label="Bathroom">
                    <Input
                      type="text"
                      placeholder="Enter number of bathrooms"
                      {...getFieldProps("total_bathrooms")}
                      className="input-field rounded-none w-full"
                    />
                    {errors.total_bathrooms && touched.total_bathrooms && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors.total_bathrooms}
                      </div>
                    )}
                  </FormItem>
                </div>
              </div>

              {/* Property Rules */}
              <h3 className="text-lg font-semibold">Property Rules</h3>
              <div className="flow-root">
                <div className="-my-3 mt-1 divide-y divide-neutral-100 dark:divide-neutral-800">
                  {initialValues.additionalRules.map((rule, index) =>
                    renderNoInclude(rule, index)
                  )}
                </div>
              </div>

              <div className="flex flex-col space-y-4 sm:space-y-0 sm:space-x-5">
                <Input
                  type="text"
                  className="input-field !h-20 !w-3/4 rounded-none"
                  placeholder="No smoking..."
                  value={newRuleInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewRuleInput(e.target.value)
                  }
                />
                <ButtonPrimary
                  type="button"
                  className="flex-shrink-0 !w-32 !mt-5"
                  onClick={handleAddRule}
                >
                  <i className="text-xl las la-plus"></i>
                  <span className="ml-3">Add</span>
                </ButtonPrimary>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">Who Lives Here?</h3>
                <table className="table-auto border-collapse w-full">
                  <thead>
                    <tr className="bg-orange-500 text-white">
                      <th className="border px-4 py-2">Name</th>
                      <th className="border px-4 py-2">Gender</th>
                      <th className="border px-4 py-2">Country</th>
                      <th className="border px-4 py-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roomies.map((roomie: any) => (
                      <>
                        <tr key={roomie.id}>
                          <td className="border px-4 py-2">
                            <input
                              type="text"
                              value={roomie.name}
                              onChange={(e) =>
                                handleInputChange(
                                  roomie.id,
                                  "name",
                                  e.target.value
                                )
                              }
                              className="w-full px-2 py-1 border rounded"
                            />
                          </td>
                          <td className="border px-4 py-2">
                            <select
                              value={roomie.gender}
                              onChange={(e) =>
                                handleInputChange(
                                  roomie.id,
                                  "gender",
                                  e.target.value
                                )
                              }
                              className="w-full px-5 py-1 border rounded mr-10"
                            >
                              <option value="">Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </td>
                          <td className="border px-4 py-2">
                            <select
                              value={roomie.country}
                              onChange={(e) =>
                                handleInputChange(
                                  roomie.id,
                                  "country",
                                  e.target.value
                                )
                              }
                              className="w-full px-2 py-1 border rounded"
                            >
                              <option value="">Select</option>
                              {__countryListing.map((country) => (
                                <option key={country.name} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="border px-4 py-2">
                            <button
                              onClick={() => handleRemoveRoomie(roomie.id)}
                              className="bg-red-500 text-white px-4 py-1 rounded"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                        {(!roomie.name ||
                          !roomie.gender ||
                          !roomie.country) && (
                          <span className="text-sm text-red-600">
                            Details are required
                          </span>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>

                <ButtonPrimary
                  type="button"
                  className="flex-shrink-0 w-48 !mt-5 flex items-center space-x-3"
                  onClick={handleAddRoomie}
                >
                  <i className="text-xl las la-plus"></i>
                  <span className="ml-3">Add Roomie</span>
                </ButtonPrimary>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-5">
                <ButtonSecondary href={`/add-listing-3?id=${globalId || id}`}>
                  Go back
                </ButtonSecondary>
                <ButtonPrimary
                  type="submit"
                  disabled={isSubmitting || disabled}
                >
                  {isSubmitting ? "Loading..." : "Continue"}
                </ButtonPrimary>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </CommonLayoutV2>
  );
};

export default ListingDetails;
